import { User } from "../models/User";

export function getUser() {
  const user = sessionStorage.getItem('user');
  if (user === 'undefined' || !user) {
    return null;
  } else {
    return JSON.parse(user);
  }

}

export function getToken() {
  return sessionStorage.getItem('token');
}

export function setUserSession(user: User, token: string) {
  sessionStorage.setItem('user', JSON.stringify(user));
  sessionStorage.setItem('token', token);
}

export function resetUserSession() {
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('token');
}

export function getRefreshToken() {
  return localStorage.getItem('refreshToken');
}

export function setRefreshToken(refreshToken: string) {
  return localStorage.setItem('refreshToken', refreshToken);
}