import axios from "axios";
import { useRef, useState } from "react";
import {
  DefaultButton,
  DefaultFullWidthButton,
} from "../../../components/Buttons";
import {
  DefaultInput,
  OtpInput,
  PasswordInput,
  TelephoneInput,
} from "../../../components/Inputs";
import { emailValidator } from "../../../lib/emailValidator";

function ForgotPasswordPage() {
  const [emailInput, setEmailInput] = useState<string>("");

  const [sending, setSending] = useState<boolean>(false);
  
  const [linkSent, setLinkSent] = useState<boolean>(false);

  const resetPasswordFunction = async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    if (!emailInput || !emailValidator(emailInput)) {
      return;
    }

    

    setSending(true);

    await axios.post(process.env.REACT_APP_API_URL + "auth/forgot-password", {
      email: emailInput,
    }, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    }).then(response => {

      setLinkSent(true);
      setSending(false)
      return;

    }).catch(error => {

      
      setSending(false)

      try {
        alert(error.response.data.message)
      }
      catch {
        alert("Something went wrong")
      }

      return;

    });
  };

  return (
    <>
      <main>
        <div className="max-w-7xl mx-auto m-0 h-screen flex items-center justify-center">
          {
            linkSent ? 
            <div className="max-w-md w-full space-y-8 px-3">
              <div>
                <img
                  className="mx-auto h-12 w-auto"
                  src="https://tailwindui.com/img/logos/workflow-mark.svg?color=emerald&shade=600"
                  alt="Workflow"
                />
                <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-gray-900">
                  Check your inbox!
                </h2>
                <p className="text-gray-600 mt-2">We've sent a reset password link to your email address. Please check your inbox and click the link to reset your password.</p>
              </div>
            </div>
            : 
            <div className="max-w-md w-full space-y-8 px-3">
              <div>
                <img
                  className="mx-auto h-12 w-auto"
                  src="https://tailwindui.com/img/logos/workflow-mark.svg?color=emerald&shade=600"
                  alt="Workflow"
                />
                <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-gray-900">
                  Reset your password
                </h2>
              </div>
              <div>
                <DefaultInput
                  label="Email"
                  placeholder="Enter your email"
                  onChangeText={(change: string) => setEmailInput(change)}
                />
                <DefaultFullWidthButton
                  text={sending ? "Requesting Password Reset" : "Reset Password"}
                  onPress={resetPasswordFunction}
                  disabled={sending || !emailInput || !emailValidator(emailInput)}
                />
              </div>
            </div>
          }
          
        </div>
      </main>
    </>
  );
}

export default ForgotPasswordPage;
