import React, { useEffect, useState } from "react";
import {
  ActivateButton,
  DefaultButton,
  DefaultFullWidthButton,
  FullWidthTextButton,
  OutlineButton,
  RadioButton,
  RedButton,
  SmallButton,
} from "../../../components/Buttons";
import { MainHeader } from "../../../components/Headers";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import axios from "axios";
import { getToken } from "../../../context/auth";

interface IssuingForm {
  inputs: {
    id: string;
    label: string;
    type: "First Name" | "Last Name" | "Phone" | "Date of Birth" | "Company Name" | "Email" | "Title";
    required: boolean;
    placeholder: string | null;
  }[];
  logoImage: string | null;
  authentication: {
    type: "email";
    validator: string | null;
  };
}

interface Design {
  backgroundColor: string;
  foregroundColor: string;
  labelColor: string;
  stripImage: string | null;
}

interface Info {
  expiryDate: {
    type: "unlimited" | "fixedTerm" | "daysFromIssue";
    expiryDate: Date | null;
    length: number | null;
  };
  activation: {
    type: "barcode" | "qr code";
    content: string;
  };
  passFields: {
    key: string;
    label: string;
    value: string;
    type: 'First Name' | 'Last Name' | 'Phone' | 'Date of Birth' | 'Expiry Date' | 'Custom Text';
  }[];
}

export default function PassPage(props: any) {
  let navigate = useNavigate();

  const params = useParams();

  const [showExportCsvModal, setShowExportCsvModal] = useState<boolean>(false);
  const [exportingCsv, setExportingCsv] = useState<boolean>(false);

  const [passId, setPassId] = useState<string>(params.passId as string);

  const [passTemplateLoading, setPassTemplateLoading] = useState<boolean>(true);

  const [passTitle, setPassTitle] = useState<string>("");
  const [passStatus, setPassStatus] = useState<'active' | 'draft' | 'deactivated'>("draft");
  const [passType, setPassType] = useState<'generic' | 'points' | 'stamps'>("generic");

  const [issuingLink, setIssuingLink] = useState<string>("");

  const [activeSlide, setActiveSlide] = useState(1);

  const [customers, setCustomers] = useState<any[]>([]);
  const [showCustomers, setShowCustomers] = useState<any[]>([]);
  
  const [issuingForm, setIssuingForm] = useState<IssuingForm>({
    inputs: [],
    logoImage: null,
    authentication: {
      type: "email",
      validator: null,
    },
  });

  const [design, setDesign] = useState<Design>({
    backgroundColor: "#ffffff",
    foregroundColor: "#000000",
    labelColor: "#000000",
    stripImage: null,
  });

  const [activation, setActivation] = useState<Info["activation"]>({
    type: "barcode",
    content: "",
  })
  const [expiryDate, setExpiryDate] = useState<Info["expiryDate"]>({
    type: "unlimited",
    expiryDate: null,
    length: null,
  })
  const [passFields, setPassFields] = useState<Info["passFields"]>([])

  const [customersPageNo, setCustomersPageNo] = useState<number>(1);

  useEffect(() => {

    

    loadPassTemplate();
    loadCustomers();
  }, []);

  const loadPassTemplate = async () => {

    axios
      .post(
        `${process.env.REACT_APP_API_URL}pass-templates/get-pass`,
        {
          templateId: passId,
        },
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: getToken() as string,
          },
        }
      )
      .then((res) => {

        const {
          title,
          design,
          type,
          info,
          issuingForm,
          status,
          issuingLink,
        } = res.data;

        setPassTitle(title);
        setPassStatus(status);
        setPassType(type);

        setIssuingForm(issuingForm);
        setDesign(design);

        setActivation(info.activation);
        setExpiryDate(info.expiryDate);
        setPassFields(info.passFields);

        setIssuingLink(issuingLink);

        setPassTemplateLoading(false);
      })
      .catch((err) => {
        
        alert("Error getting pass template");
      });
  };

  const loadCustomers = async () => {

    axios
      .post(
        `${process.env.REACT_APP_API_URL}pass-templates/customers/get-customers`,
        {
          templateId: passId,
        },
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: getToken() as string,
          },
        }
      )
      .then((res) => {

        

        // get issuing form inputs

        setCustomers(
          res.data.customers.map((customer: any) => {

            return customer;
          })
        );

        // refreshCustomersList(1, res.data.customers)

      })
      .catch((err) => {
        
        alert("Error getting pass customers");
      });
  };

  const refreshCustomersList = (pageNo: number, customersList: any[]) => {

    

    if (pageNo < 1) {
      return;
    }

    if (pageNo > Math.ceil(customersList.length / 5)) {
      return;
    }

    setCustomersPageNo(pageNo);

    const skipAmount = (pageNo - 1) * 5;

    const nextCustomers = customersList.splice(skipAmount, 5);
    
    setShowCustomers(nextCustomers as any[]);  
  }

  const deleteDraftFunction = () => {
    if (passStatus === 'active') {
      alert("An active pass cannot be deleted");
      return;
    }

    axios
      .post(
        process.env.REACT_APP_API_URL + "pass-templates/delete-template",
        {
          templateId: passId,
        },
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: getToken() as string,
          },
        }
      )
      .then((response) => {
        navigate("/passes");
      })
      .catch((error) => {
        
        alert(error.response.data.message);
      });
  };

  const deactivatePassFunction = () => {
    if (passStatus !== 'active') {
      alert("This pass is inactive");
      return;
    }

    axios
      .post(
        process.env.REACT_APP_API_URL + "pass-templates/deactivate-template",
        {
          templateId: passId,
        },
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: getToken() as string,
          },
        }
      )
      .then((response) => {
        setPassStatus('deactivated')
        alert("Pass deactivated");
      })
      .catch((error) => {
        
        alert(error.response.data.message);
      });
  };

  const activatePassFunction = () => {
    if (passStatus === 'active') {
      alert("This pass is already active");
      return;
    }

    axios
      .post(
        process.env.REACT_APP_API_URL + "pass-templates/activate-template",
        {
          templateId: passId,
        },
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: getToken() as string,
          },
        }
      )
      .then((response) => {
        
        setPassStatus('active');
        alert("Pass activated");
      })
      .catch((error) => {
        
        alert(error.response.data.message);
      });
  };

  const exportCsvFunction = () => {
    if (customers.length === 0) {
      alert("There are no customers to export");
      return;
    }

    axios
      .post(
        process.env.REACT_APP_API_URL + "pass-templates/customers/export-csv",
        {
          templateId: passId,
        },
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: getToken() as string,
          },
        }
      )
      .then((response) => {
        

        window.open(response.data.url, '_blank');

        setShowExportCsvModal(false);
      })
      .catch((error) => {
        
        alert(error.response.data.message);
      });
  };

  return (
    <>
      <div className="min-h-screen h-full bg-gray-50">
        <MainHeader />
        {
          passTemplateLoading ? null :
          <header className="bg-white shadow">
            <div className="flex flex-row justify-between items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl tracking-tight font-bold text-gray-900">
                { passTitle }{passStatus === 'active' ? null : ` (${passStatus.toUpperCase()})`}
              </h1>
              {
                passTemplateLoading ? null : 
                <div className="flex flex-row">
                  {
                    passStatus !== 'active' ? 
                    <DefaultButton
                      text={"Edit"}
                      onPress={() => {
                        navigate(`/passes/${passId}/edit-pass`);
                      }}
                      disabled={false}
                    /> : null
                  }
                  {passStatus !== 'active' ? (
                    <RedButton
                      text={"Delete Draft"}
                      onPress={deleteDraftFunction}
                    />
                  ) : null}
                  {passStatus === 'active' ? (
                    <RedButton
                      text={"Deactivate Pass"}
                      onPress={deactivatePassFunction}
                      disabled={false}
                    />
                  ) : (
                    <ActivateButton
                      text={"Activate Pass"}
                      onPress={activatePassFunction}
                      disabled={false}
                    />
                  )}
                </div>
              }
            </div>
          </header>
        }
        <main>
          {
            passTemplateLoading ?
            <div className="flex h-full w-full justify-center items-center">
              <p className="text-gray-300">Loading...</p>
            </div> :
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              <div className="px-4 py-6 sm:px-0">
                <div className="h-full">
                  <div className="w-full flex flex-row justify-between mb-5">
                    <div className="grid grid-cols-2 md:flex md:flex-row gap-2">
                      <RadioButton
                        text={"Dashboard"}
                        onPress={() => {
                          setActiveSlide(1);
                        }}
                        active={activeSlide === 1 ? true : false}
                      />
                      <RadioButton
                        text={"Customers"}
                        onPress={() => {
                          setActiveSlide(2);
                        }}
                        active={activeSlide === 2 ? true : false}
                      />
                      {/* <RadioButton
                        text={"Push Notifications"}
                        onPress={() => {
                          setActiveSlide(3);
                        }}
                        active={activeSlide === 3 ? true : false}
                      /> */}
                      <RadioButton
                        text={"Issuing Form"}
                        onPress={() => {
                          setActiveSlide(4);
                        }}
                        active={activeSlide === 4 ? true : false}
                      />
                      {/* <RadioButton
                        text={"Other"}
                        onPress={() => {
                          setActiveSlide(5);
                        }}
                        active={activeSlide === 5 ? true : false}
                      /> */}
                    </div>
                  </div>
                  {activeSlide === 1 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                      <div className="bg-white min-h-80 h-full drop-shadow rounded-lg flex flex-col p-4 hover:opacity-70 hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 border border-transparent shadow-sm text-sm">
                        <h3 className="text-2xl text-gray-900 font-semibold mb-2">
                          Preview
                        </h3>
                        <QRCodeCanvas
                          value={`issuing.walletpass.co.uk/passes/${passId}`}
                          className="mb-2"
                        />
                        <DefaultButton
                          text={"Preview Issuing Form"}
                          onPress={() => {
                            window.open(
                              `https://issuing.walletpass.co.uk/passes/${passId}`,
                              "_blank"
                            );
                          }}
                          disabled={false}
                        />
                      </div>
                      <div className="bg-white min-h-80 h-full drop-shadow rounded-lg flex flex-col p-4 hover:opacity-70 hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 border border-transparent shadow-sm text-sm">
                        <h3 className="text-2xl text-gray-900 font-semibold mb-2">
                          Template Info
                        </h3>
                        <div className="grid grid-cols-2 gap-4">
                          {
                            expiryDate ?
                            <div className="mb-1">
                              <h4 className="font-bold">Card Expiry</h4>
                              {
                                expiryDate.type === 'unlimited' ?
                                <div>
                                  <p>Type: Unlimited</p>
                                </div> : null
                              }
                              {
                                expiryDate.type === 'fixedTerm' ?
                                <div>
                                  <p>Type: Fixed Term</p>
                                  <p>Expiry Date: { new Date(expiryDate.expiryDate as Date).toDateString() }</p>
                                </div> : null
                              }
                              {
                                expiryDate.type === 'daysFromIssue' ?
                                <div>
                                  <p>Type: Days From Issue</p>
                                  <p>Days From Issue: { expiryDate.length }</p> : null
                                </div> : null
                              }
                            </div> : null
                          }
                          {
                            activation ?
                            <div className="mb-1">
                              <h4 className="font-bold">Card Activation</h4>
                              <p>Type: { activation.type.toUpperCase() }</p>
                              <p>Contents: { activation.content }</p>
                            </div> : null
                          }
                        </div>
                      </div>

                      <div className="bg-white min-h-80 h-full drop-shadow rounded-lg flex flex-col hover:opacity-70 hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 border border-transparent shadow-sm text-sm">
                        <div className="p-4">
                          <h3 className="text-2xl text-gray-900 font-semibold">
                            Pass Fields
                          </h3>
                        </div>

                        {/* Table with 2 columns */}
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Field Name
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Data Type
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {
                              passFields.map((passField, passFieldIndex) => {
                                return (
                                  <tr key={passFieldIndex}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="ml-4">
                                          <div className="text-sm font-medium text-gray-900">
                                            {passField.label}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="ml-4">
                                          <div className="text-sm font-medium text-gray-900">
                                            {passField.type}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : null}
                  {activeSlide === 2 ? (
                    <div className="">
                      <div className="card rounded border-1 drop-shadow bg-white">
                        <div className="px-4 py-3 flex items-center justify-between sm:px-4">
                          <h3 className="text-xl text-gray-900 font-semibold">
                            Customers
                          </h3>
                          <SmallButton
                            text={"Export as CSV"}
                            onPress={() => setShowExportCsvModal(true)}
                            disabled={customers.length > 0 ? false : true}
                          />
                        </div>
                        <div className="overflow-x-auto">
                          {customers.length > 0 ? (
                            <table className=" w-full table-auto">
                              <thead>
                                <tr>
                                  <th className="px-4 py-2 text-left">Email</th>
                                  {issuingForm.inputs.map((input, inputIndex) => {
                                    if (
                                      input.type === 'First Name' ||
                                      input.type === 'Last Name' ||
                                      input.type === 'Title' ||
                                      input.type === 'Company Name' ||
                                      input.type === 'Phone' ||
                                      input.type === 'Email' ||
                                      input.type === 'Date of Birth' ||
                                      input.type === 'Address'
                                    ) {
                                      return (
                                        <th key={inputIndex} className="px-4 py-2 text-left">
                                          {input.label}
                                        </th>
                                      );
                                    } else {
                                      return (
                                        <th key={inputIndex} className="px-4 py-2 text-left">
                                          {input.label}
                                        </th>
                                      );
                                    }
                                  })}
                                  <th className="px-4 py-2 text-left">Controls</th>
                                </tr>
                              </thead>
                              <tbody>
                                {customers.map((customer, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="border px-4 py-2">{(customer as any).email}</td>
                                      {issuingForm.inputs.map(
                                        (input, inputIndex) => {

                                          let selector = input.label.replaceAll(/\s/g, "_").toLowerCase()

                                          if (
                                            input.type === 'First Name' ||
                                            input.type === 'Last Name' ||
                                            input.type === 'Title' ||
                                            input.type === 'Company Name' ||
                                            input.type === 'Phone' ||
                                            input.type === 'Email' ||
                                            input.type === 'Date of Birth' ||
                                            input.type === 'Address'
                                          ) {
                                            selector = input.type.replaceAll(/\s/g, "_").toLowerCase();
                                          }

                                          return (
                                            <td
                                              key={inputIndex}
                                              className="border px-4 py-2"
                                            >
                                              {(customer as any)[selector] || "Not Found"}
                                            </td>
                                          );
                                        }
                                      )}
                                      <td className="border px-4 py-2">
                                        <DefaultButton
                                          text={"View"}
                                          onPress={() => {
                                            navigate('/customers/' + customer.id);
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <div className="py-5">
                              <p className="text-center text-gray-600">
                                No customers yet
                              </p>
                            </div>
                          )}
                        </div>
                        {/* {showCustomers.length > 0 ? (
                          <div className="px-4 py-3 flex items-center justify-between sm:px-4">
                            <h3 className="text-sm text-gray-600">
                              Showing 1 to 10 of { customers.length } results
                            </h3>
                            <div className="flex flex-row">
                              <OutlineButton
                                text={"Previous"}
                                onPress={() => refreshCustomersList(customersPageNo - 1, customers)}
                                disabled={customersPageNo <= 1 ? true : false}
                              />
                              <OutlineButton
                                text={"Next"}
                                onPress={() => refreshCustomersList(customersPageNo + 1, customers)}
                                disabled={false}
                              />
                            </div>
                          </div>
                        ) : null} */}
                      </div>
                    </div>
                  ) : null}
                  {activeSlide === 4 ? (
                    <div className="">
                      <div className="card rounded border-1 drop-shadow bg-white">
                        <div className="px-4 py-3 flex flex-col sm:px-4">
                          <h3 className="text-xl text-gray-900 font-semibold mb-2">
                            Issuing Form
                          </h3>
                          <p>
                            Email Authentication:{" "}
                            {issuingForm.authentication.type === "email"
                              ? "Yes"
                              : "No"}
                          </p>
                          <p>
                            Student Email:{" "}
                            {issuingForm.authentication.validator === ".ac.uk"
                              ? "Yes"
                              : "No"}
                          </p>
                        </div>
                        <div className="">
                          {issuingForm.inputs.length > 0 ? (
                            <table className=" w-full table-auto">
                              <thead>
                                <tr>
                                  <th className="text-start border px-4 py-3 text-gray-900">
                                    Label
                                  </th>
                                  <th className="text-start border px-4 py-3 text-gray-900">
                                    Field Type
                                  </th>
                                  <th className="text-start border px-4 py-3 text-gray-900">
                                    Required
                                  </th>
                                  <th className="text-start border px-4 py-3 text-gray-900">
                                    Placeholder
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {issuingForm.inputs.map((input, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="border px-4 py-1 text-gray-600">
                                        {input.label}
                                      </td>
                                      <td className="border px-4 py-1 text-gray-600">
                                        {input.type}
                                      </td>
                                      <td className="border px-4 py-1 text-gray-600">
                                        {input.required ? "Yes" : "No"}
                                      </td>
                                      <td className="border px-4 py-1 text-gray-600">
                                        {input.placeholder || `Enter your ${input.label.toLowerCase()}`}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <div className="py-5">
                              <p className="text-center text-gray-600">
                                This form has no fields
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          }
        </main>
        {
          showExportCsvModal ?
          <div className="fixed inset-0 z-40 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25">
            <div className="absolute inset-0 bg-gray-500 bg-opacity-25">
              {/* Create a modal element */}

              <div className="absolute inset-0 flex items-center justify-center">
                <div className="max-w-lg w-full px-4 py-3">
                  <div className="flex flex-col justify-between bg-white rounded w-full p-5 drop-shadow">
                    <div className="flex flex-col">
                      <h2 className="text-xl font-semibold mb-3">Export as CSV</h2>
                      <p className="text-gray-600 mb-5">Are you sure you want to export all customers?</p>
                      <div className="flex flex-row justify-end">
                        <OutlineButton text={"Cancel"} onPress={() => setShowExportCsvModal(false)} />
                        <DefaultButton text={ exportingCsv ? "Exporting..." : "Export"} disabled={exportingCsv} onPress={exportCsvFunction} />
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div> : null
        }
      </div>
    </>
  );
}
