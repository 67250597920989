import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { DefaultButton, SmallButton } from '../../../components/Buttons';
import { AdminHeader, MainHeader } from '../../../components/Headers'
import { getToken } from '../../../context/auth';

interface UserAccount {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  accountStatus: 'active' | 'deleted';
}

function AdminUsersPage() {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [users, setUsers] = useState<UserAccount[]>([]);

  useEffect(() => {
    loadPasses()
  }, [])
  

  const loadPasses = async() => {
    

    // axios post request
    await axios.get(process.env.REACT_APP_API_URL + 'admin/users/get', {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Authorization": await getToken() as string
      },
    }).then((response) => {

      

      const { users } = response.data;

      setUsers(users);
      setIsLoading(false);


    }).catch(error => {
      
      try {
        alert(error.response.data.message)
      }
      catch {
        alert("Something went wrong")
      }
    })
  }

  return (
    <>
      <div className="min-h-screen bg-gray-50">
        <AdminHeader />
        <header className="bg-white shadow">
          <div className="flex flex-row justify-between items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl tracking-tight font-bold text-gray-900">Users</h1>
            <DefaultButton
              text={"New User +"}
              onPress={() => {
                navigate(`/users/create-user`);
              }}
              disabled={false}
            />
          </div>
        </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            {
              isLoading ? 
              <div className="flex justify-center items-center">
                <p className="text-gray-300">Loading...</p>
              </div> :
              <div className="card rounded border-1 drop-shadow bg-white">
                {/* Create a table of users */}

                <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Company Name
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Account Email
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Account Status
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                View
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {
                              users.map((user, userIndex) => {
                                return (
                                  <tr key={userIndex}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="ml-4">
                                          <div className="text-sm font-medium text-gray-900">
                                            {user.companyName || 'N/A'}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="ml-4">
                                          <div className="text-sm font-medium text-gray-900">
                                            {user.email || 'N/A'}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="ml-4">
                                          <div className="text-sm font-medium text-gray-900 capitalize">
                                            {user.accountStatus || 'N/A'}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                    <SmallButton
                                      text={"View"}
                                      onPress={() => { navigate(`/users/${user.id}`) }}
                                    />
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>

                
              </div>
            }
          </div>
        </div>
      </main>
      </div>
    </>
  )
}

export default AdminUsersPage;
