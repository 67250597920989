import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { DefaultButton, OutlineButton, RedButton } from '../../../components/Buttons';
import { MainHeader } from '../../../components/Headers'
import { DefaultSelect } from '../../../components/Selects';
import { getToken } from '../../../context/auth';

function PassesPage() {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [templates, setTemplates] = useState<{ id: string, title: string, headerImage: string }[]>([]);

  const [showCreatePassTemplateModal, setShowCreatePassTemplateModal] = useState<boolean>(false);

  const [passTypeInput, setPassTypeInput] = useState<string>('');

  useEffect(() => {
    loadPasses()
  }, [])
  

  const loadPasses = async() => {
    

    // axios post request
    await axios.get(process.env.REACT_APP_API_URL + 'pass-templates/get-templates', {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Authorization": await getToken() as string
      },
    }).then((response) => {

      

      const { templates } = response.data;

      setTemplates(templates);
      setIsLoading(false);


    }).catch(error => {
      
      try {
        alert(error.response.data.message)
      }
      catch {
        alert("Something went wrong")
      }
    })
  }

  return (
    <>
      <div className="min-h-screen bg-gray-50">
        <MainHeader />
        <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl tracking-tight font-bold text-gray-900">My Passes</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            {
              isLoading ? 
              <div className="flex h-screen w-screen justify-center items-center">
                <p className="text-gray-300">Loading...</p>
              </div> :
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <Link onClick={() => { setShowCreatePassTemplateModal(true); } } className="h-80 border-4 border-dashed border-gray-300 rounded-lg flex items-center justify-center hover:opacity-70 hover:cursor-pointer" to={''}>
                  <h3 className="text-2xl text-gray-300 font-semibold">New Pass +</h3>
                </Link>
                {
                  templates.map((template) => {
                    return (
                      <Link key={template.id} to={`/passes/${template.id}`} className="bg-white h-80 drop-shadow rounded-lg flex flex-col items-center justify-center hover:opacity-70 hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 border border-transparent shadow-sm text-sm">
                        {
                          template.headerImage ?
                          <img src={template.headerImage} className="w-full h-40 object-cover rounded-t-lg" /> :
                          <div className="w-full h-80 object-cover rounded-t-lg bg-gray-300 flex items-center justify-center">
                            <p className="text-white text-bold">No Header Image</p>
                          </div>
                        }
                        <div className="w-full h-full flex flex-col items-center justify-center">
                          <h3 className="text-2xl text-gray-900 font-semibold text-center">{ template.title }</h3>
                        </div>
                      </Link>
                    )
                  })
                }
              </div>
            }
          </div>
        </div>
      </main>
      </div>
      {
        showCreatePassTemplateModal ? (
          <div className="fixed inset-0 z-40 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25">
            <div className="absolute inset-0 bg-gray-500 bg-opacity-25">
              {/* Create a modal element */}
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="max-w-lg w-full px-4 py-3">
                  <div className="flex flex-col justify-between bg-white rounded w-full p-5 drop-shadow">
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold mb-3">Select a Pass Type</h2>
                        <p className="text-gray-500 text-xs mb-5">You will not be able to change this.</p>
                        <DefaultSelect placeholder={'Select a pass type'} options={[
                          'Generic',
                          'Points',
                          'Stamps',
                          'Savings',
                          'Identity',
                        ]} value={passTypeInput} onSelect={(change: string) => { setPassTypeInput(change); }} />
                        <div className="flex flex-row justify-end">
                          <OutlineButton text={"Cancel"} onPress={() => setShowCreatePassTemplateModal(false)} />
                          <DefaultButton text={'Continue'} disabled={!passTypeInput} onPress={() => {
                            navigate(`/passes/create-new-pass${passTypeInput ? `?passType=${(passTypeInput as string).toLowerCase()}` : ''}`)
                          }} />
                        </div>
                        {/* TODO: Loading Spinner */}
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        ) : null
      }
    </>
  )
}

export default PassesPage;
