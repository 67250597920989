import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ActivateButton,
  DefaultButton,
  OutlineButton,
  RadioButton,
  RedRemoveButton,
} from "../../../components/Buttons";
import { MainHeader } from "../../../components/Headers";
import {
  ColourInput,
  DateInput,
  DefaultInput,
  FileInput,
  MultilineInput,
  NumberInput,
} from "../../../components/Inputs";
import { DefaultSelect } from "../../../components/Selects";
import { getToken } from "../../../context/auth";
import { convertDateToString, convertDateToStringYYYYMMDD } from "../../../lib/convertDateToString";
import { isDateExpired } from "../../../lib/isDateExpired";
import { PassField, PassIssuingFormInput } from "../../../models/PassTemplate";

function EditPassPage() {

  const navigate = useNavigate();

  const params = useParams();

  const [passId, setPassId] = useState<string>(params.passId as string);

  const [passTemplateLoading, setPassTemplateLoading] = useState<boolean>(true);

  const [passType, setPassType] = useState<string>("");

  const [status, setStatus] = useState<string>("");

  useEffect(() => {
    
    loadPassTemplate()
    
  }, [])
  


  const [activeSlide, setActiveSlide] = useState(1);

  const [showUploadAssetsPopup, setShowUploadAssetsPopup] = useState(false);

  const [templateTitleInput, setTemplateTitleInput] = useState<string>("");

  const [templateCardExpiryInput, setTemplateCardExpiryInput] =
    useState<string>("No Expiry Date");

  const [templateCardExpiryDateInput, setTemplateCardExpiryDateInput] =
    useState<string>('');

  const [templateCardExpiryDaysFromIssueInput, setTemplateCardExpiryDaysFromIssueInput] = useState<string>("");

  const [templatePassFields, setTemplatePassFields] = useState<{ id: number; name: string; type: string; value: string }[]>([]);

  const [templateBarcodeType, setTemplateBarcodeType] = useState<string>("Barcode");

  const [templateBarcodeContent, setTemplateBarcodeContent] =
    useState<string>("");

  // Pass Design Inputs

  const [initialLogoImage, setInitialLogoImage] = useState<string>("");
  const [issuingLogoFile, setIssuingLogoFile] = useState<File | null>(null);
  const [issuingLogoFileName, setIssuingLogoFileName] = useState<string>("");

  const [initialStripImage, setInitialStripImage] = useState<string>("");
  const [designStripFile, setDesignStripFile] = useState<File | null>(null);
  const [designStripFileName, setDesignStripFileName] = useState<string>('');

  const [foregroundColor, setForegroundColor] = useState<string>("#ffffff");
  const [backgroundColor, setBackgroundColor] = useState<string>("#000000");
  const [labelColor, setLabelColor] = useState<string>("#ffffff");

  const [passFieldOptions, setPassFieldOptions] = useState<string[]>([
    "Full Name",
    "Phone",
    "Expiry Date",
    "Custom Text"
  ]);

  // Issuing Form Inputs

  const [issuingFormInputFields, setIssuingFormInputFields] = useState<{ id: number, label: string, type: string, required: boolean, placeholder: string, options: string[] }[]>([]);

  const [issuingFormAuthenticationEmailStudent, setIssuingFormAuthenticationEmailStudent] = useState<'Yes' | 'No' | ''>('');

  const [pointsConfig, setPointsConfig] = useState<{ pointsPerCurrency: number }>({
    pointsPerCurrency: 10
  });

  const [stampsConfig, setStampsConfig] = useState<{ stampsBeforeRedemption: number }>({
    stampsBeforeRedemption: 5,
  })

  // functions

  const loadPassTemplate = async () => {

    axios.post(`${process.env.REACT_APP_API_URL}pass-templates/get-pass`, {
      templateId: passId,
    }, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: getToken() as string,
      },
    }).then((res) => {

      const { title, type, status, design, info, issuingForm, pointsConfiguration, stampsConfiguration } = res.data;

      setTemplateTitleInput(title);
      setPassType(type);
      setStatus(status);
      if (info.expiryDate.type === 'unlimited') {
        setTemplateCardExpiryInput("No Expiry Date");
      } else if (info.expiryDate.type === 'fixedTerm') {
        setTemplateCardExpiryInput('Expiry Date');
        setTemplateCardExpiryDateInput(convertDateToStringYYYYMMDD(new Date(info.expiryDate.expiryDate)));
      } else if (info.expiryDate.type === 'daysFromIssue') {
        setTemplateCardExpiryInput('Days From Issue');
        setTemplateCardExpiryDaysFromIssueInput(info.expiryDate.daysFromIssue);
      }

      if (info.passFields.length > 0) {
        setTemplatePassFields(info.passFields.map((field: PassField) => {
          return {
            id: Math.random(),
            name: field.label,
            type: field.type,
          };
        }));
        
      }

      if (info.activation.type === 'barcode') {

        setTemplateBarcodeType('Barcode');
        setTemplateBarcodeContent(info.activation.content);
      } else if (info.activation.type === 'qr code') {
        setTemplateBarcodeType('QR Code');
        setTemplateBarcodeContent(info.activation.content);
      }

      if (type === 'points') {
        setPointsConfig({
          pointsPerCurrency: pointsConfiguration.pointsPerCurrency,
        })
      }

      if (type === 'stamps') {
        setStampsConfig({
          stampsBeforeRedemption: stampsConfiguration.stampsBeforeRedemption,
        })
      }

      setForegroundColor(design.foregroundColor);
      setBackgroundColor(design.backgroundColor);
      setLabelColor(design.labelColor);

      
      if (design.stripImage) {
        setInitialStripImage(design.stripImage)
      }
      
      if (issuingForm.logoImage) {
        setInitialLogoImage(issuingForm.logoImage);
      }

      if (issuingForm.inputs.length > 0) {
        setIssuingFormInputFields(issuingForm.inputs.map((field: PassIssuingFormInput) => {
          return {
            id: Math.random(),
            label: field.label,
            type: field.type,
            required: field.required,
            placeholder: field.placeholder,
            options: field.options,
          }
        }));
        
      }

      if (issuingForm.authentication.validator === '.ac.uk') {
        setIssuingFormAuthenticationEmailStudent('Yes');
      }

      setPassTemplateLoading(false);

    }).catch((err) => {
      alert("Error getting pass template");
    })
  }

  const addPassDataFieldFunction = () => {
    if (templatePassFields.length >= 5) {
      return;
    }

    const oldFields = templatePassFields;

    const randomId = Math.random();

    oldFields.push({
      id: randomId,
      name: "",
      type: "",
      value: "",
    });

    setTemplatePassFields(oldFields.slice(0));
  };

  const removePassFieldElementFunction = (id: number) => {
    setTemplatePassFields((prev) => prev.filter((o, i) => id !== o.id));
  };

  const templateInfoValid = () => {
    if (
      !templateTitleInput ||
      !templateCardExpiryInput ||
      (templateCardExpiryInput === "Expiry Date" && (!templateCardExpiryDateInput || isDateExpired(new Date(templateCardExpiryDateInput)))) ||
      templatePassFields.length < 1 ||
      templatePassFields.length > 5 ||
      !templateInfoPassFieldsArrayValid()
    ) {
      
      return false;
    } else {

      

      if (passType === 'generic' && (!templateBarcodeContent || !templateBarcodeType )) {
        return false;
      }

      return true;
    }
  }

  const templateInfoPassFieldsArrayValid = () => {
    if (templatePassFields.length < 1 || templatePassFields.length > 5) {
      return false;
    } else {

      // loop through each field and check if name and type are valid

      for (let i = 0; i < templatePassFields.length;) {
        if (!templatePassFields[i].name || !templatePassFields[i].type) {
          return false;
        }

        i++

        if (i >= templatePassFields.length) {
          return true;
        }
      }
    }
  }

  const designValid = () => {

    if (
      (!designStripFile && !initialStripImage) ||
      !foregroundColor ||
      !backgroundColor ||
      !labelColor
    ) {
      return false;
    } else {
      return true;
    }
  }

  const issuingFormValid = () => {

    if (
      (!issuingLogoFile && !initialLogoImage) ||
      !issuingFormFieldsArrayValid()
    ) {
      return false;
    } else {
      return true;
    }
  }

  const issuingFormFieldsArrayValid = () => {
    if (issuingFormInputFields.length < 1 || issuingFormInputFields.length > 10) {
      return false;
    } else {

      // loop through fields and check if any are empty

      for (let i = 0; i < issuingFormInputFields.length;) {
        if (!issuingFormInputFields[i].label || !issuingFormInputFields[i].type) {
          return false;
        }

        // If the label or the type are used multiple times, return false

        for (let j = 0; j < issuingFormInputFields.length;) {
          if (issuingFormInputFields[i].label === issuingFormInputFields[j].label && i !== j) {
            return false;
          }

          if (issuingFormInputFields[i].type === issuingFormInputFields[j].type && i !== j) {
            return false;
          }

          j++

          if (j >= issuingFormInputFields.length) {
            break;
          }
        }

        i++

        if (i >= issuingFormInputFields.length) {
          return true;
        }
      }

    }
  }

  const pointsValid = () => {
    if (passType === 'points') {
      
      if (pointsConfig.pointsPerCurrency <= 1) {
        return false;
      }

      return true;

    } else {
      return true;
    }
  }

  const stampsValid = () => {
    if (passType === 'stamps') {
      
      if (!Number(stampsConfig.stampsBeforeRedemption) || stampsConfig.stampsBeforeRedemption < 1) {
        return false;
      }

      return true;

    } else {
      return true;
    }
  }

  // TODO: Changing slide resets the value of the elements

  const editPassFunction = () => {
    
    if (!designValid() || !issuingFormValid() || !templateInfoValid()) {
      alert('Please fill out all required fields')
      return;
    }

    let expiryDate = null;

    if (templateCardExpiryInput === "Expiry Date") {
      expiryDate = {
        type: "fixedTerm",
        expiryDate: new Date(templateCardExpiryDateInput),
        length: null,
      }
    } else {
      expiryDate = {
        type: "unlimited",
        expiryDate: null,
        length: null,
      }
    }


    const otherData: any = {
      templateId: passId,
      title: templateTitleInput,
      expiryDate: expiryDate,
      activation: {
        type: templateBarcodeType.toLowerCase(),
        content: templateBarcodeContent
      },
      passFields: templatePassFields.map((field) => {
        return {
          key: field.name.toLowerCase().replace(/ /g, '_'),
          label: field.name,
          type: field.type,
          value: field.value ? field.value : null,
        }
      }),
      design: {
        foregroundColor: foregroundColor,
        backgroundColor: backgroundColor,
        labelColor: labelColor,
      },
      issuingForm: {
        authentication: {
          type: 'email',
          validator: issuingFormAuthenticationEmailStudent === 'Yes' ? '.ac.uk' : null
        },
        inputs: issuingFormInputFields.map((field) => {

          // remove empty array elements

          let mappedOptions: string[] = [];

          if (field.type === 'Dropdown') {
            mappedOptions = field.options.filter((option) => option !== '');
          }

          return {
            id: field.label.toLowerCase().replace(/ /g, '_'),
            label: field.label,
            type: field.type,
            required: field.required,
            placeholder: field.placeholder,
            options: field.type === 'Dropdown' ? mappedOptions : null
          }
        })
      },
    }

    if (passType === 'points') {
      otherData['pointsConfig'] = {
        pointsPerCurrency: pointsConfig.pointsPerCurrency,
      }
    }

    if (passType === 'stamps') {
      otherData['stampsConfig'] = {
        stampsBeforeRedemption: stampsConfig.stampsBeforeRedemption
      }
    }

    if (passType === 'savings') {
      otherData['savingsConfig'] = {
        currency: 'gbp'
      }
    }

    console.log(otherData);

    axios.post(process.env.REACT_APP_API_URL + 'pass-templates/edit-template', otherData, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Authorization": getToken() as string
      },
    }).then(async(response) => {

      

      if (designStripFile || issuingLogoFile) {

        setShowUploadAssetsPopup(true);
  
        let uploadedHeader: true | false = designStripFile ? false : true;
        let uploadedLogo: true | false = issuingLogoFile ? false : true;
  
        
  
        // upload icon image and header image to s3 url
  
        if (issuingLogoFile) {
          
          
          axios.put(response.data.logoS3Url, issuingLogoFile, {
            headers: {
              'Content-Type': 'image/png',
              'Allow-Control-Allow-Origin': '*',
            }
          }).then(response => {
    
            uploadedLogo = true;
    
            if (uploadedHeader === true) {
              setShowUploadAssetsPopup(false);
              navigate('/passes')
            }
          }).catch(error => {
            throw error;
          })
  
        }
  
        if (designStripFile) {
  
          axios.put(response.data.headerS3Url, designStripFile, {
            headers: {
              'Content-Type': 'image/png',
              'Allow-Control-Allow-Origin': '*',
            }
          }).then(() => {
    
            uploadedHeader = true;
    
            if (uploadedLogo === true) {
              setShowUploadAssetsPopup(false);
              navigate('/passes')
            }
          }).catch(error => {
            throw error;
          })
  
  
        }
      } else {
        navigate('/passes')
      }


    }).catch((error) => {
      try {
        
        alert(error.response.data.message)
        setShowUploadAssetsPopup(false);

      }
      catch(error) {
        alert('Something went wrong')
      }
    })

  }

  // TODO: Expiry Date validation

  return (
    <>
      <div className="min-h-screen bg-gray-50">
        <MainHeader />
        {
          passTemplateLoading ? null :
          <header className="bg-white shadow">
            <div className="flex flex-row justify-between items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl tracking-tight font-bold text-gray-900">
                Edit Pass
              </h1>
              <ActivateButton
                text={"Save Changes"}
                onPress={editPassFunction}
                disabled={(!designValid() || !issuingFormValid() || !templateInfoValid()) ? true : false}
              />
            </div>
          </header>
        }
        <main>
          {
            passTemplateLoading ?
            <div className="flex h-full w-full justify-center items-center">
              <p className="text-gray-300">Loading...</p>
            </div> :
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              <div className="px-4 py-6 sm:px-0 drop-shadow">
                <div className="flex flex-col  h-full">
                  <div className="w-full h-full bg-white p-5">
                    <div className="w-full flex flex-row justify-between mb-5">
                      <div className="flex flex-row gap-2">
                        <RadioButton
                          text={"Template Info"}
                          onPress={() => {
                            setActiveSlide(1);
                          }}
                          active={activeSlide === 1 ? true : false}
                          complete={templateInfoValid() ? true : false}
                        />
                        <RadioButton
                          text={"Pass Design"}
                          onPress={() => {
                            setActiveSlide(2);
                          }}
                          active={activeSlide === 2 ? true : false}
                          complete={designValid() ? true : false}
                        />
                        <RadioButton
                          text={"Issuing Form"}
                          onPress={() => {
                            setActiveSlide(3);
                          }}
                          active={activeSlide === 3 ? true : false}
                          complete={issuingFormValid() ? true : false}
                        />
                        {
                          passType === 'points' ?
                          <RadioButton
                            text={"Points"}
                            onPress={() => {
                              setActiveSlide(4);
                            }}
                            active={activeSlide === 4 ? true : false}
                            complete={pointsValid() ? true : false}
                          /> : null
                        }
                        {
                          passType === 'stamps' ?
                          <RadioButton
                            text={"Stamps"}
                            onPress={() => {
                              setActiveSlide(5);
                            }}
                            active={activeSlide === 5 ? true : false}
                            complete={stampsValid() ? true : false}
                          /> : null
                        }
                      </div>
                    </div>
                    {activeSlide === 1 ? (
                      <div>
                        <h1 className="text-3xl font-medium text-gray-900 mb-5">
                          Template Info.
                        </h1>
                        <p className="mb-5 text-gray-600">
                        Tell us about your new pass. What's it called? What data do you want to display on the pass (e.g. Full Name, Expiry Date, etc)? And most importantly, the code that your user is scanning!
                        </p>
                        <DefaultInput
                          label="Title"
                          placeholder="Enter the template's title"
                          value={templateTitleInput}
                          onChangeText={(change: string) =>
                            setTemplateTitleInput(change)
                          }
                        />
                        <DefaultSelect
                          label="Card Expiry"
                          placeholder="Select an expiry date"
                          options={["No Expiry Date", "Expiry Date"]}
                          value={templateCardExpiryInput}
                          onSelect={(change: string) =>
                            setTemplateCardExpiryInput(change)
                          }
                        />
                        {templateCardExpiryInput === "Expiry Date" ? (
                          <DateInput
                            label="Expiry Date"
                            value={templateCardExpiryDateInput} 
                            onChangeText={(change: string) =>
                              setTemplateCardExpiryDateInput(change)
                            }
                          />
                        ) : null}
                        <div className="flex flex-row justify-between mb-2">
                          <div>
                            <h2 className="text-2xl text-gray-600 font-medium mb-2">
                              Pass Fields ({templatePassFields.length}/5)
                            </h2>
                            {
                              passType === 'generic' ?
                              <p className="text-gray-600">
                                Add up to 5 fields to your pass. These fields will be displayed on the pass.
                              </p> : null
                            }
                            {
                              passType === 'points' ?
                              <p className="text-gray-600">
                                We'll automatically add a "Points" and a "Points Value" field to your pass.
                              </p> : null
                            }
                            {
                              passType === 'stamps' ?
                              <p className="text-gray-600">
                                We'll automatically add a "Stamps" field to your pass.
                              </p> : null
                            }
                            {
                              passType === 'savings' ?
                              <p className="text-gray-600">
                                We'll automatically add a "Money Saved" field to your pass.
                              </p> : null
                            }
                          </div>
                          <DefaultButton
                            text={"Add Field"}
                            disabled={templatePassFields.length >= 5}
                            onPress={addPassDataFieldFunction}
                          />
                        </div>
                        {templatePassFields.map((field, index) => {
                          return (
                            <div
                              key={field.id}
                              className="flex flex-row items-center w-full justify-between"
                            >
                              <DefaultInput
                                label="Field Name"
                                placeholder="Enter a field name"
                                className="w-full mr-2"
                                value={field.name}
                                onChangeText={(change: string) => {
                                  const newFields = [...templatePassFields];
                                  newFields[index].name = change;
                                  setTemplatePassFields(newFields);
                                }}
                              />
                              <DefaultSelect
                                label="Field Data"
                                options={passFieldOptions}
                                placeholder="Select field data"
                                value={field.type}
                                onSelect={(change: string) => {
                                  const newFields = [...templatePassFields];
                                  newFields[index].type = change;
                                  setTemplatePassFields(newFields);
                                }}
                                className="w-full mr-2"
                              />
                              {
                                field.type === 'Custom Text' ? 
                                (
                                  <DefaultInput
                                    label="Custom Text"
                                    placeholder="Enter custom text"
                                    className="w-full mr-2"
                                    value={field.value}
                                    onChangeText={(change: string) => {
                                      const newFields = [...templatePassFields];
                                      newFields[index].value = change;
                                      setTemplatePassFields(newFields);
                                    }}
                                  />
                                ) : null
                              }
                              <RedRemoveButton
                                icon={"lol"}
                                disabled={templatePassFields.length <= 1}
                                onPress={() =>
                                  removePassFieldElementFunction(field.id)
                                }
                              />
                            </div>
                          );
                        })}
                        {
                          passType === 'generic' ?
                          <div>
                            <h2 className="text-2xl font-medium text-gray-600 mb-2">
                              Barcode
                            </h2>
                            <DefaultSelect
                              label="Barcode Type"
                              placeholder="Select a barcode type"
                              options={["Barcode", "QR Code"]}
                              value={templateBarcodeType}
                              onSelect={(change: string) =>
                                setTemplateBarcodeType(change)
                              }
                            />
                            <DefaultInput
                              label="Barcode Contents"
                              placeholder="Enter the bardcode contents"
                              value={templateBarcodeContent}
                              onChangeText={(change: string) =>
                                setTemplateBarcodeContent(change)
                              }
                            />
                          </div> : null
                        }
                        <div className="flex flex-row">
                          <DefaultButton
                            text="Continue"
                            disabled={templateInfoValid() ? false : true}
                            onPress={() => {
                              setActiveSlide(2);
                            }}
                            className=""
                          />
                        </div>
                      </div>
                    ) : null}
                    {activeSlide === 2 ? (
                      <div>
                        <h1 className="text-3xl font-medium text-gray-900 mb-5">
                          Pass Design.
                        </h1>
                        <p className="mb-5 text-gray-600">
                          Here's where you can make your pass look pretty! Choose your colours and upload an image!
                        </p>
                        {
                          initialStripImage ? 
                          <div>
                            <p className="text-gray-600 text-sm mb-1">Strip Image (.png)</p>
                            <div className="flex flex-row justify-between mb-5">
                              <img src={initialStripImage} className="w-1/2" />
                              <RedRemoveButton onPress={() => { setInitialStripImage(""); setDesignStripFile(null); } } icon={""} />
                            </div>
                          </div> :
                          <FileInput label="Strip Image (.png)" note="Recommended size: 358x130" accept=".png" onChange={(change:any) => {
                            if (change.target.files[0].size > 10000000) {
                              alert("File is too big");
                              return;
                            }

                            
                        
                            setDesignStripFile(change.target.files[0]);
                            setDesignStripFileName(change.target.files[0].name);
                          }}  />
                        }
                        <ColourInput label="Text Colour" value={foregroundColor} onChangeText={(change: string) => { setForegroundColor(change) }} />
                        <ColourInput label="Label Colour" value={labelColor} onChangeText={(change: string) => { setLabelColor(change) }} />
                        <ColourInput label="Background Colour" value={backgroundColor} onChangeText={(change: string) => { setBackgroundColor(change) }} />
                        <div className="flex flex-row">
                          <OutlineButton
                            text="Back"
                            onPress={() => {
                              setActiveSlide(1);
                            }}
                            className=""
                          />
                          <DefaultButton
                            text="Continue"
                            disabled={templateInfoValid() ? false : true}
                            onPress={() => {
                              setActiveSlide(3);
                            }}
                            className=""
                          />
                        </div>
                      </div>
                    ) : null}
                    {activeSlide === 3 ? (
                      <div>
                        <h1 className="text-3xl font-medium text-gray-900 mb-5">
                          Issuing Form.
                        </h1>
                        <p className="mb-5 text-gray-600">
                          Here's where you can create the form that your customers use to sign up for your pass! You can tell them all about your card, add form fields to get them to enter their data (e.g. First Name, Phone Number, etc).
                        </p>
                        <p className="mb-5 text-sm text-gray-600">
                          We automatically collect their email address, so you don't need to worry about specifying this. However, you can decide if you want to restrict your pass to student email addresses (.ac.uk)!
                        </p>
                        {
                          initialLogoImage ? 
                          <div>
                            <p className="text-gray-600 text-sm mb-1">Logo Image (.png)</p>
                            <div className="flex flex-row justify-between mb-5">
                              <img src={initialLogoImage} className="w-1/2" />
                              <RedRemoveButton onPress={() => { setInitialLogoImage(""); setIssuingLogoFile(null); } } icon={""} />
                            </div>
                          </div> :
                          <FileInput label="Logo Image (.png)" accept=".png" onChange={(change:any) => {
                            if (change.target.files[0].size > 10000000) {
                              alert("File is too big");
                              return;
                            }
                        
                            setIssuingLogoFile(change.target.files[0]);
                            setIssuingLogoFileName(change.target.files[0].name);
                          }} />
                        }
                        <div className="flex flex-row justify-between mb-2">
                          <h2 className="text-2xl text-gray-900 font-medium">
                            Form Fields
                          </h2>
                          <DefaultButton text={"Add Field"} onPress={() => {
                            setIssuingFormInputFields([...issuingFormInputFields, {
                              id: Math.random(),
                              label: "",
                              type: "",
                              required: true,
                              placeholder: "",
                              options: []
                            }])
                          }} disabled={issuingFormInputFields.length >= 10} />
                        </div>
                        {
                          issuingFormInputFields.map((field, index) => {
                            return (
                              <div key={field.id} className="flex flex-row items-center w-full justify-between">
                                <DefaultInput
                                  label="Field Label"
                                  placeholder="Enter a field label"
                                  className="w-full mr-2"
                                  value={field.label}
                                  onChangeText={(change: string) => setIssuingFormInputFields(prev => {
                                    const newArray = [...prev];
                                    newArray[index].label = change;
                                    return newArray;
                                  })}
                                />
                                <DefaultSelect
                                  label="Input Type"
                                  options={[
                                    "First Name",
                                    "Last Name",
                                    "Phone",
                                    "Date of Birth",
                                    "Company Name",
                                    "URL",
                                    "Text",
                                    "Number",
                                    "Date",
                                    "Dropdown"
                                  ]}
                                  value={field.type}
                                  placeholder="Select input type"
                                  className="w-full mr-2"
                                  onSelect={(change: string) => setIssuingFormInputFields(prev => {
                                    return [...prev.slice(0, index), { ...prev[index], type: change }, ...prev.slice(index + 1)]
                                  })}
                                />
                                <DefaultSelect
                                  label="Required?"
                                  options={["Yes", "No"]}
                                  placeholder="Select required"
                                  className="w-full mr-2"
                                  value={field.required ? "Yes" : "No"}
                                  onSelect={(change: string) => setIssuingFormInputFields(prev => {
                                    return [...prev.slice(0, index), { ...prev[index], required: change === "Yes" }, ...prev.slice(index + 1)]
                                  })}
                                />
                                <DefaultInput
                                  label="Placeholder"
                                  placeholder="Enter a placeholder"
                                  className="w-full mr-2"
                                  value={field.placeholder}
                                  onChangeText={(change: string) => setIssuingFormInputFields(prev => {
                                    const newArray = [...prev];
                                    newArray[index].placeholder = change;
                                    return newArray;
                                  })}
                                />
                                {
                                  field.type === "Dropdown" ? (
                                    <DefaultInput
                                      label="Options"
                                      placeholder="Option 1, Option 2, Option 3"
                                      className="w-full mr-2"
                                      value={field.options.join(", ")}
                                      onChangeText={(change: string) => setIssuingFormInputFields(prev => {
                                        const newArray = [...prev];
                                        newArray[index].options = change.split(", ")
                                        return newArray;
                                      })}
                                      note={'(Comma Separated)'}
                                    /> ) : null
                                }
                                <RedRemoveButton icon={"x"} onPress={() => {
                                  if (issuingFormInputFields.length <= 1) {
                                    return;
                                  }
                                  setIssuingFormInputFields(issuingFormInputFields.filter(f => f.id !== field.id))
                                }} disabled={issuingFormInputFields.length <= 1} />
                              </div>
                            )
                          })
                        }
                        <h2 className="text-2xl text-gray-900 font-medium mb-2">
                          Authentication
                        </h2>
                        <DefaultSelect
                          label="Should the customer's email address be a student email address?"
                          options={["Yes", "No"]}
                          value={issuingFormAuthenticationEmailStudent ? "Yes" : "No"}
                          placeholder="Select student email authentication"
                          onSelect={(change: string) => setIssuingFormAuthenticationEmailStudent(change === "Yes" ? "Yes" : "No")}
                        />
                        
                        <div className="flex flex-row">
                          <OutlineButton
                            text="Back"
                            onPress={() => {
                              setActiveSlide(3);
                            }}
                            className=""
                          />
                          {
                            passType === 'points' || passType === 'stamps' ? (
                              <DefaultButton
                                text="Continue"
                                disabled={issuingFormValid() ? false : true}
                                onPress={() => {
                                  if (passType === 'points') {
                                    setActiveSlide(4);
      
                                  } else if (passType === 'stamps'){
                                    setActiveSlide(5);
                                  }
                                }}
                                className=""
                              /> ) : null
                          }
                        </div>
                      </div>
                    ) : null}
                    {activeSlide === 4 ? (
                    <div>
                      <h1 className="text-3xl font-medium text-gray-900 mb-5">
                        Points Setup
                      </h1>
                      <p className="mb-5 text-gray-600">
                        Here's where you can set up your points scheme! You can decide how many points a customer earns when they spend money at your business.
                      </p>
                      <NumberInput
                        label="How many points do customers earn for every £1 spent?"
                        placeholder="10"
                        value={pointsConfig.pointsPerCurrency as unknown as string}
                        onChangeText={(change: string) => setPointsConfig({
                          ...pointsConfig,
                          pointsPerCurrency: parseInt(change)
                        })}
                        note='Each point is worth £0.01'
                      />
                      <div className="flex flex-row">
                        <OutlineButton
                          text="Back"
                          onPress={() => {
                            setActiveSlide(3);
                          }}
                          className=""
                        />
                      </div>
                    </div>
                  ) : null}
                  {activeSlide === 5 ? (
                    <div>
                      <h1 className="text-3xl font-medium text-gray-900 mb-5">
                        Stamps Setup
                      </h1>
                      <p className="mb-5 text-gray-600">
                        Here's where you can set up your stamps scheme! You can decide how many stamps a customer earns when they spend money at your business, and how many stamps they need to earn to get a free item.
                      </p>
                      <NumberInput
                        label="How many stamps do customers need to earn before they can redeem a free item?"
                        placeholder="10"
                        value={stampsConfig.stampsBeforeRedemption as unknown as string}
                        onChangeText={(change: string) => setStampsConfig({
                          ...stampsConfig,
                          stampsBeforeRedemption: parseInt(change)
                        })}
                        note='minimum 1'
                      />
                      <div className="flex flex-row">
                        <OutlineButton
                          text="Back"
                          onPress={() => {
                            setActiveSlide(3);
                          }}
                          className=""
                        />
                      </div>
                    </div>
                  ) : null}
                  </div>
                </div>
              </div>
            </div>
          }
        </main>
      </div>
      {
      showUploadAssetsPopup ? (
        <div className="fixed inset-0 z-40 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25">
            <div className="absolute inset-0 bg-gray-500 bg-opacity-25">
              {/* Create a modal element */}

              <div className="absolute inset-0 flex items-center justify-center">
                <div className="max-w-lg w-full px-4 py-3">
                  <div className="flex flex-col justify-between items-center bg-white rounded w-full p-5 drop-shadow">
                    <div className="flex flex-col">
                      <h2 className="text-xl font-semibold mb-3">Uploading Assets</h2>
                      <p className="text-gray-600 mb-5">Please be patient while we upload your creative assets.</p>
                      {/* TODO: Loading Spinner */}
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      ) : null
}
    </>
  );
}

export default EditPassPage;