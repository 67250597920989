import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import {
  ActivateButton,
  DefaultButton,
  DefaultFullWidthButton,
  FullWidthTextButton,
  OutlineButton,
  RadioButton,
  RedRemoveButton,
  SmallButton,
} from "../../../components/Buttons";
import { MainHeader } from "../../../components/Headers";
import {
  ColourInput,
  DateInput,
  DefaultInput,
  FileInput,
  MultilineInput,
  NumberInput,
} from "../../../components/Inputs";
import { DefaultSelect } from "../../../components/Selects";
import { getToken, getUser } from "../../../context/auth";
import { isDateExpired } from "../../../lib/isDateExpired";
import { PassField } from "../../../models/PassTemplate";

interface PassFieldElement {
  id: number;
  label: string;
  type: string;
  customText: string;
}

interface IssuingFormField {
  id: number;
  label: string;
  type: string;
  placeholder: string;
  required: boolean;
  options: string[];
}

function CreatePassTemplatePage() {

  const navigate = useNavigate();

  const [slide, setSlide] = useState<string>("name");

  // title data

  const [title, setTitle] = useState<string>("");

  const validateTitle = () => {
    if (title.length < 1) {
      return false;
    }
    return true;
  };

  // pass type data

  const [type, setType] = useState<string>("generic");

  // expiry data

  const [expiry, setExpiry] = useState<string>("No Expiry Date");
  const [templateCardExpiryDateInput, setTemplateCardExpiryDateInput] = useState<string>('');

  // design data

  const [backgroundColour, setBackgroundColour] = useState<string>("#ffffff");
  const [textColour, setTextColour] = useState<string>("#000000");
  const [passImageFile, setPassImageFile] = useState<File | null>(null);
  const [passImageFileName, setPassImageFileName] = useState<string>("");

  const [passCustomerProfileImage, setPassCustomerProfileImage] = useState<'Yes' | 'No' | ''>('No');

  // fields data

  const [passFieldOptions, setPassFieldOptions] = useState<string[]>([
    "Full Name",
    "Expiry Date",
    "Custom Text"
  ]);

  const [passFields, setPassFields] = useState<PassFieldElement[]>([
    {
      id: 0,
      label: "Full Name",
      type: "Full Name",
      customText: "",
    }
  ]);

  const removePassFieldElementFunction = (id: number) => {
    setPassFields((prev) => prev.filter((o, i) => id !== o.id));
  };

  const passFieldsValid = () => {
    if (passFields.length < 1 || passFields.length > 5) {
      return false;
    } else {

      // loop through each field and check if name and type are valid

      for (let i = 0; i < passFields.length;) {
        if (!passFields[i].label || !passFields[i].type) {
          return false;
        }

        i++

        if (i >= passFields.length) {
          return true;
        }
      }
    }
  }

  // barcode data

  const [barcodeType, setBarcodeType] = useState<string>("QR Code");
  const [barcodeContent, setBarcodeContent] = useState<string>("");

  // issuing form data

  const [formFields, setFormFields] = useState<IssuingFormField[]>([]);
  const [studentEmail, setStudentEmail] = useState<'Yes' | 'No' | ''>('No');
  
  const [issuingFormLogoFile, setIssuingFormLogoFile] = useState<File | null>(null);
  const [issuingFormLogoFileName, setIssuingFormLogoFileName] = useState<string>("");

  const issuingFormFieldsValid = () => {

      // loop through fields and check if any are empty

      if (formFields.length < 1) {

        for (let i = 0; i < formFields.length;) {
          if (!formFields[i].label || !formFields[i].type) {
            return false;
          }
  
          if (formFields[i].type === 'Dropdown' && formFields[i].options.length < 1) {
            return false;
          }
  
          // If the label or the type are used multiple times, return false
  
          for (let j = 0; j < formFields.length;) {
            if (formFields[i].label === formFields[j].label && i !== j) {
              return false;
            }
  
            if (formFields[i].type === formFields[j].type && i !== j) {
              return false;
            }
  
            j++
  
            if (j >= formFields.length) {
              break;
            }
          }
  
          i++
  
          if (i >= formFields.length) {
            return true;
          }
        }
      }

      return true;
  }

  // points data

  const [pointsPerCurrency, setPointsPerCurrency] = useState<number>(1);

  // stamps data

  const [stampsBeforeRedemption, setStampsBeforeRedemption] = useState<number>(1);

  // create template

  const [creatingPass, setCreatingPass] = useState<boolean>(false);

  const [showUploadAssetsPopup, setShowUploadAssetsPopup] = useState<boolean>(false);

  const createPassFunction = async() => {

    if (
      !title ||
      !type ||
      !expiry ||
      (expiry === "Expiry Date" && !templateCardExpiryDateInput) ||
      !backgroundColour ||
      !textColour ||
      !passImageFile ||
      !passFields ||
      !passFields ||
      !formFields ||
      !issuingFormLogoFile ||
      (type === "points" && !pointsPerCurrency) ||
      (type === "stamps" && !stampsBeforeRedemption)
    ) {
      alert("Please fill out all fields");
      return;
    }
    
    let expiryDate = null;

    if (expiry === "Expiry Date") {
      expiryDate = {
        type: "fixedTerm",
        expiryDate: new Date(templateCardExpiryDateInput),
        length: null,
      }
    } else {
      expiryDate = {
        type: "unlimited",
        expiryDate: null,
        length: null,
      }
    }

    let otherData: any = {
      title: title,
      expiryDate: expiryDate,
      activation: {
        type: barcodeType.toLowerCase(),
        content: type === 'generic' ? barcodeContent : null,
        thirdPartyRedemptionMethod: null
      },
      passType: type.toLowerCase(),
      passFields: passFields.map((field) => {
        return {
          key: field.label.toLowerCase().replace(/ /g, '_'),
          label: field.label,
          type: field.type,
          value: (field.type === 'Custom Text' && field.customText) ? field.customText : null,
        }
      }),
      design: {
        foregroundColor: textColour,
        backgroundColor: backgroundColour,
        labelColor: textColour,
        showCustomerProfileImage: passCustomerProfileImage === 'Yes' ? true : false,
      },
      issuingForm: {
        authentication: {
          type: 'email',
          validator: studentEmail === 'Yes' ? '.ac.uk' : null
        },
        inputs: formFields.map((field) => {

          // remove empty array elements

          let mappedOptions: string[] = [];

          if (field.type === 'Dropdown') {
            mappedOptions = field.options.filter((option) => option !== '');
          }

          return {
            id: field.label.toLowerCase().replace(/ /g, '_'),
            label: field.label,
            type: field.type,
            required: field.required,
            placeholder: field.placeholder,
            options: field.type === 'Dropdown' ? mappedOptions : null
          }
        })
      },
    }

    if (type === 'points') {
      otherData['pointsConfig'] = {
        pointsPerCurrency: pointsPerCurrency
      }
    }

    if (type === 'stamps') {
      otherData['stampsConfig'] = {
        stampsBeforeRedemption: stampsBeforeRedemption
      }
    }

    if (type === 'savings') {
      otherData['savingsConfig'] = {
        currency: 'gbp'
      }
    }


    setCreatingPass(true);

    axios.post(process.env.REACT_APP_API_URL + 'pass-templates/create-template', otherData, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Authorization": getToken() as string
      },
    }).then(async(response) => {

      if (issuingFormLogoFile || passImageFile) {
        

        setShowUploadAssetsPopup(true);
  
        let uploadedHeader: true | false = passImageFile ? false : true;
        let uploadedLogo: true | false = issuingFormLogoFile ? false : true;
  
        
  
        // upload icon image and header image to s3 url
  
        if (issuingFormLogoFile) {
          const uploadLogo = await axios.put(response.data.logoS3Url, issuingFormLogoFile, {
            headers: {
              'Content-Type': 'image/png',
              'Allow-Control-Allow-Origin': '*',
            }
          })
  
          
  
          uploadedLogo = true;
  
          if (uploadedHeader === true) {
            setShowUploadAssetsPopup(false);
            navigate('/passes')
          }
        }
  
        if (passImageFile) {
          const uploadHeader = await axios.put(response.data.headerS3Url, passImageFile, {
            headers: {
              'Content-Type': 'image/png',
              'Allow-Control-Allow-Origin': '*',
            }
          })
  
          
  
          uploadedHeader = true;
  
          if (uploadedLogo === true) {
            setShowUploadAssetsPopup(false);
            navigate('/passes')
          }
  
        }
      } else {
        navigate('/passes')
      }

      setCreatingPass(false);


    }).catch((error) => {
      try {
        setCreatingPass(false);
        alert(error.response.data.message)

      }
      catch {
        alert('Something went wrong')
      }
    })

  }

  return (
    <>
      <div className="min-h-screen bg-gray-50">
        <MainHeader />
        <main>
          <div className="relative overflow-hidden w-screen min-h-screen bg-gradient-to-r from-violet-500 to-red-400">
            {
              slide === "name" ? (
                <div className="sm:max-w-md mx-auto min-h-screen flex items-center justify-center">
                  <div className="w-screen md:w-full h-screen sm:h-auto bg-white px-5 pb-7 pt-7 rounded-none sm:rounded-3xl flex flex-col justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
                    <h2 className="text-2xl font-bold mb-1">What do you want to call your pass?</h2>
                    <p className="text-sm text-gray-400 mb-3">Your customers will be able to see the name of your pass.</p>
                    <DefaultInput 
                      placeholder="The Super Cool Loyalty Pass"
                      value={title}
                      onChangeText={(e: string) => setTitle(e)}
                      className="w-full"
                    />
                    <div className="w-full">
                      <DefaultFullWidthButton text={"Next"} onPress={() => {
                        if (!validateTitle()) {
                          alert("Please enter a title for your pass.");
                          return;
                        }

                        setSlide('type')
                      }} />                 
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
              slide === "type" ? (
                <div className="sm:max-w-md mx-auto min-h-screen flex items-center justify-center">
                  <div className="w-screen md:w-full h-screen sm:h-auto bg-white px-5 pb-7 pt-7 rounded-none sm:rounded-3xl flex flex-col justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
                    <h2 className="text-2xl font-bold mb-1">What kind of pass do you want to create?</h2>
                    <p className="text-sm text-gray-400 mb-3">We offer a range of different pass types for various business cases!</p>
                    <DefaultSelect placeholder={'Select a pass type'} options={[
                      'generic',
                      'points',
                      'stamps',
                      'savings',
                      'identity',
                    ]} value={type} onSelect={(change: string) => { setType(change); }} />
                    <div className="w-full flex flex-row">
                      <FullWidthTextButton text={"Back"} onPress={() => {
                        setSlide('name')
                      }} className="w-full" />
                      <DefaultFullWidthButton text={"Next"} onPress={() => {
                        if (!type) {
                          alert("Please select a pass type.");
                          return;
                        }

                        setSlide('expiry')
                      }} className="w-full" />                 
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
              slide === "expiry" ? (
                <div className="sm:max-w-md mx-auto min-h-screen flex items-center justify-center">
                  <div className="w-screen md:w-full h-screen sm:h-auto bg-white px-5 pb-7 pt-7 rounded-none sm:rounded-3xl flex flex-col justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
                    <h2 className="text-2xl font-bold mb-1">Do you want your pass to expire?</h2>
                    <p className="text-sm text-gray-400 mb-3">Once the expiry date has passed, your customers will not be able to use the pass.</p>
                    <DefaultSelect
                        placeholder="Select an expiry date"
                        options={["Never Expire", "Expiry Date"]}
                        value={expiry}
                        onSelect={(change: string) =>
                          setExpiry(change)
                        }
                        className="w-full"
                      />
                      {
                        expiry === "Expiry Date" ? (
                          <DateInput
                            label="Expiry Date"
                            value={templateCardExpiryDateInput} 
                            onChangeText={(change: string) =>
                              setTemplateCardExpiryDateInput(change)
                            }
                            className="w-full"
                          />
                        ) : null
                      }
                    <div className="w-full flex flex-row">
                      <FullWidthTextButton text={"Back"} onPress={() => {
                        setSlide('type')
                      }} className="w-full" />
                      <DefaultFullWidthButton text={"Next"} onPress={() => {
                        if (!expiry) {
                          alert("Please select an expiry date.");
                          return;
                        }

                        if (expiry === "Expiry Date" && !templateCardExpiryDateInput) {
                          alert("Please select an expiry date.");
                          return;
                        }

                        setSlide('issuingFormInfo')
                      }} className="w-full" />                 
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
              slide === "issuingFormInfo" ? (
                <div className="sm:max-w-md mx-auto min-h-screen flex items-center justify-center">
                  <div className="w-screen md:w-full h-screen sm:h-auto bg-white px-5 pb-7 pt-7 rounded-none sm:rounded-3xl flex flex-col justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
                    <h2 className="text-2xl font-bold mb-1">Create your Issuing Form</h2>
                    <p className="text-sm text-gray-400 mb-3">You can customise the questions that your customers are asked when they sign up for your pass</p>
                    <p className="text-sm text-gray-600 mb-3">By default, we collect the following fields:</p>
                    {/* Create a bull point list */}
                    <ul className="list-disc list-inside text-sm text-gray-600 mb-3">
                      <li>First Name</li>
                      <li>Last Name</li>
                      <li>Email Address</li>
                      <li>Secondary Email Address (if the primary email is a student email address)</li>
                      <li>Phone Number</li>
                      <li>City</li>
                    </ul>
                    <div className="w-full flex flex-row">
                      <FullWidthTextButton text={"Back"} onPress={() => {
                        setSlide('expiry')
                      }} className="w-full" />
                      <DefaultFullWidthButton text={"Next"} onPress={() => {
                        setSlide('issuingFormLogo')
                      }} className="w-full" />                 
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
              slide === "issuingFormLogo" ? (
                <div className="sm:max-w-md mx-auto min-h-screen flex items-center justify-center">
                  <div className="w-screen md:w-full h-screen sm:h-auto bg-white px-5 pb-7 pt-7 rounded-none sm:rounded-3xl flex flex-col justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
                    <h2 className="text-2xl font-bold mb-1">Add your branding</h2>
                    <p className="text-sm text-gray-400 mb-3">Add your logo to the top of your issuing form!</p>
                    <FileInput
                      accept="image/*"
                      label="Logo"
                      className="w-full"
                      onChange={(change: any) => {
                        if (change.target.files[0].size > 5000000) {
                          alert("File is too big");
                          return;
                        }

                        setIssuingFormLogoFile(change.target.files[0]);
                        setIssuingFormLogoFileName(change.target.files[0].name);
                      }}
                    />
                    <div className="w-full flex flex-row">
                      <FullWidthTextButton text={"Back"} onPress={() => {
                        setSlide('issuingFormInfo')
                      }} className="w-full" />
                      <DefaultFullWidthButton text={"Next"} onPress={() => {
                        if (!issuingFormLogoFile) {
                          alert("Please upload a logo");
                          return;
                        }

                        if (issuingFormLogoFile.size > 5000000) {
                          alert("File is too big");
                          return;
                        }

                        setSlide('issuingFormFields')
                      }} className="w-full" />                 
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
              slide === "issuingFormFields" ? (
                <div className="sm:max-w-xl mx-auto min-h-screen flex items-center justify-center">
                  <div className="w-screen md:w-full h-screen sm:h-auto bg-white px-5 pb-7 pt-7 rounded-none sm:rounded-3xl flex flex-col justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
                    <div className="flex flex-row justify-between">
                      <div className="">
                        <h2 className="text-2xl font-bold mb-1">What additional questions you would like to ask your customers?</h2>
                        <p className="text-sm text-gray-400 mb-3">You can leave this blank.</p>
                      </div>
                      <SmallButton className="ml-2" text="Add Form Field" disabled={formFields.length === 4} onPress={() => {
                        if (formFields.length >= 4) {
                          alert("You can only add up to 4 fields.");
                          return;
                        }

                        setFormFields([...formFields, {
                          id: passFields.length,
                          label: "",
                          type: "",
                          required: false,
                          placeholder: "",
                          options: [],
                        }]);
                      }} />
                    </div>
                    {formFields.map((field, index) => {
                        return (
                          <div
                            key={field.id}
                            className="flex flex-row items-center w-full justify-between"
                          >
                            <DefaultInput
                                label="Field Label"
                                placeholder="Enter a field label"
                                className="w-full mr-2"
                                value={field.label}
                                onChangeText={(change: string) => setFormFields(prev => {
                                  const newArray = [...prev];
                                  newArray[index].label = change;
                                  return newArray;
                                })}
                              />
                              <DefaultSelect
                                label="Input Type"
                                options={[
                                  "First Name",
                                  "Last Name",
                                  "Phone",
                                  "Date of Birth",
                                  "Date",
                                  "Company Name",
                                  "Email",
                                  "Text",
                                  "Number",
                                  "Dropdown"
                                ]}
                                value={field.type}
                                placeholder="Select input type"
                                className="w-full mr-2"
                                onSelect={(change: string) => setFormFields(prev => {
                                  return [...prev.slice(0, index), { ...prev[index], type: change }, ...prev.slice(index + 1)]
                                })}
                              />
                              <DefaultSelect
                                label="Required?"
                                options={["Yes", "No"]}
                                placeholder="Select required"
                                className="w-full mr-2"
                                value={field.required ? "Yes" : "No"}
                                onSelect={(change: string) => setFormFields(prev => {
                                  return [...prev.slice(0, index), { ...prev[index], required: change === "Yes" }, ...prev.slice(index + 1)]
                                })}
                              />
                              <DefaultInput
                                label="Placeholder"
                                placeholder="Enter a placeholder"
                                className="w-full mr-2"
                                value={field.placeholder}
                                onChangeText={(change: string) => setFormFields(prev => {
                                  const newArray = [...prev];
                                  newArray[index].placeholder = change;
                                  return newArray;
                                })}
                              />
                              {
                                field.type === "Dropdown" ? (
                                  <DefaultInput
                                    label="Options"
                                    placeholder="Option 1, Option 2, Option 3"
                                    className="w-full mr-2"
                                    value={field.options.join(", ")}
                                    onChangeText={(change: string) => setFormFields(prev => {
                                      const newArray = [...prev];
                                      newArray[index].options = change.split(", ")
                                      return newArray;
                                    })}
                                    note={'(Comma Separated)'}
                                  /> ) : null
                              }
                              <RedRemoveButton icon={"x"} onPress={() => {
                                if (formFields.length <= 1) {
                                  return;
                                }
                                setFormFields(formFields.filter(f => f.id !== field.id))
                              }} disabled={formFields.length <= 1} />
                          </div>
                        );
                      })}
                    <div className="w-full flex flex-row">
                      <FullWidthTextButton text={"Back"} onPress={() => {
                        setSlide('issuingFormLogo')
                      }} className="w-full" />
                      <DefaultFullWidthButton text={"Next"} onPress={() => {

                        if (!issuingFormFieldsValid()) {
                          alert("Please fill out all fields");
                          return;
                        }

                        setSlide('passDesignInfo')
                        
                        const defaultFields = ['Full Name', 'Expiry Date', 'Custom Text']
                        
                        const newFields = formFields.map((field) => {
                          return `Issuing Form: ${field.label}`
                        })

                        // add default fields to the end of the array

                        const allFields = [...newFields, ...defaultFields]

                        setPassFieldOptions(allFields)

                      }} className="w-full" />                 
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
              slide === "passDesignInfo" ? (
                <div className="sm:max-w-md mx-auto min-h-screen flex items-center justify-center">
                  <div className="w-screen md:w-full h-screen sm:h-auto bg-white px-5 pb-7 pt-7 rounded-none sm:rounded-3xl flex flex-col justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
                    <h2 className="text-2xl font-bold mb-1">Design your pass</h2>
                    <p className="text-sm text-gray-400 mb-3">You can customise the look of your pass when it appears in your customers' digital wallets.</p>
                    <div className="w-full flex flex-row">
                      <FullWidthTextButton text={"Back"} onPress={() => {
                        setSlide('issuingFormFields')
                      }} className="w-full" />
                      <DefaultFullWidthButton text={"Next"} onPress={() => {
                        setSlide('design')
                      }} className="w-full" />                 
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
              slide === "design" ? (
                <div className="sm:max-w-md mx-auto min-h-screen flex items-center justify-center">
                  <div className="w-screen md:w-full h-screen sm:h-auto bg-white px-5 pb-7 pt-7 rounded-none sm:rounded-3xl flex flex-col justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
                    <h2 className="text-2xl font-bold mb-1">Tell us what you want your pass to look like.</h2>
                    <p className="text-sm text-gray-400 mb-3">You can customise the colours and set your own image!</p>
                    <ColourInput
                      label="Background Colour"
                      value={backgroundColour}
                      onChangeText={(change: string) =>
                        setBackgroundColour(change)
                      }
                      className="w-full"
                    />
                    <ColourInput
                      label="Text Colour"
                      value={textColour}
                      onChangeText={(change: string) =>
                        setTextColour(change)
                      }
                      className="w-full"
                    />
                    <FileInput
                      label="Pass Image"
                      // value={passImageFileName}
                      onChange={(change: any) => {
                        if (change.target.files[0].size > 5000000) {
                          alert("File is too big");
                          return;
                        }

                        setPassImageFile(change.target.files[0]);
                        setPassImageFileName(change.target.files[0].name);
                      }}
                      accept=".png"
                      className="w-full"
                      note="max file size 5MB - PNG format only"
                    />
                    <DefaultSelect label="Show the customer's profile picture on the pass?" placeholder={"Select an option"} options={[
                      'Yes',
                      'No'
                    ]} onSelect={(change: string) => {
                      setPassCustomerProfileImage(change === 'Yes' ? 'Yes' : 'No')
                    }} note="The profile picture will be shown in the centre of the pass image" />
                    <div className="w-full flex flex-row">
                      <FullWidthTextButton text={"Back"} onPress={() => {
                        setSlide('passDesignInfo')
                      }} className="w-full" />
                      <DefaultFullWidthButton text={"Next"} onPress={() => {
                        if (!backgroundColour || !textColour || !passImageFile || !passImageFileName || !passCustomerProfileImage) {
                          alert("Please fill out all of the fields.");
                          return;
                        }
                        
                        // if the image is too large
                        if (passImageFile.size > 5000000) {
                          alert("Please select an image that is less than 5MB.");
                          return;
                        }

                        setSlide('passFields')
                      }} className="w-full" />                 
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
              slide === "passFields" ? (
                <div className="sm:max-w-xl mx-auto min-h-screen flex items-center justify-center">
                  <div className="w-screen md:w-full h-screen sm:h-auto bg-white px-5 pb-7 pt-7 rounded-none sm:rounded-3xl flex flex-col justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
                    <div className="flex flex-row justify-between">
                      <div className="">
                        <h2 className="text-2xl font-bold mb-1">What information would you like to display on the front of your pass?</h2>
                        <p className="text-sm text-gray-400 mb-3">You can add up to 4 fields.</p>
                      </div>
                      <SmallButton className="ml-2" text="Add Field" disabled={passFields.length === 4} onPress={() => {
                        if (passFields.length >= 4) {
                          alert("You can only add up to 4 fields.");
                          return;
                        }

                        setPassFields([...passFields, {
                          id: passFields.length,
                          label: "",
                          type: "",
                          customText: "",
                        }]);
                      }} />
                    </div>
                    {passFields.map((field, index) => {
                        return (
                          <div
                            key={field.id}
                            className="flex flex-row items-center w-full justify-between"
                          >
                            <DefaultInput
                              label="Label"
                              placeholder="Enter a field name"
                              className="w-full mr-2"
                              value={field.label}
                              onChangeText={(change: string) => {
                                const newFields = [...passFields];
                                newFields[index].label = change;
                                setPassFields(newFields);
                              }}
                            />
                            <DefaultSelect
                              label="Field Data"
                              options={passFieldOptions}
                              placeholder="Select field data"
                              value={field.type}
                              onSelect={(change: string) => {
                                const newFields = [...passFields];
                                newFields[index].type = change;
                                setPassFields(newFields);
                              }}
                              className="w-full mr-2"
                            />
                            {
                              field.type === 'Custom Text' ? 
                              (
                                <DefaultInput
                                  label="Custom Text"
                                  placeholder="Enter custom text"
                                  className="w-full mr-2"
                                  value={field.customText}
                                  onChangeText={(change: string) => {
                                    const newFields = [...passFields];
                                    newFields[index].customText = change;
                                    setPassFields(newFields);
                                  }}
                                />
                              ) : null
                            }
                            <RedRemoveButton
                              icon={""}
                              disabled={passFields.length <= 1}
                              onPress={() =>
                                removePassFieldElementFunction(field.id)
                              }
                            />
                          </div>
                        );
                      })}
                    <div className="w-full flex flex-row justify-between">
                      <FullWidthTextButton text={"Back"} onPress={() => {
                        setSlide('design')
                      }} className="w-full" />
                      <DefaultFullWidthButton text={"Next"} onPress={() => {
                        if (!passFieldsValid()) {
                          alert("Please fill out all of the fields.");
                          return;
                        }

                        console.log('type', type)
                        
                        if (type === 'points') {
                          setSlide('points')
                        } else if (type === 'stamps') {
                          setSlide('stamps')
                        } else {
                          setSlide('passActivation')
                        }
                      }} className="w-full" />                 
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
              slide === "passActivation" ? (
                <div className="sm:max-w-xl mx-auto min-h-screen flex items-center justify-center">
                  <div className="w-screen md:w-full h-screen sm:h-auto bg-white px-5 pb-7 pt-7 rounded-none sm:rounded-3xl flex flex-col justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
                    <div className="flex flex-col">
                      <h2 className="text-2xl font-bold mb-1">Encode your pass's barcode.</h2>
                      <p className="text-sm text-gray-400 mb-3">Customise your pass's activation method.</p>
                    </div>
                    <DefaultSelect
                      label="Barcode Type"
                      placeholder="Select a barcode type"
                      options={["Barcode", "QR Code"]}
                      value={barcodeType}
                      onSelect={(change: string) =>
                        setBarcodeType(change)
                      }
                    />
                    {
                      type === 'generic' ? (
                        <DefaultInput
                          label="Barcode Contents"
                          placeholder="Enter the bardcode contents"
                          value={barcodeContent}
                          onChangeText={(change: string) =>
                            setBarcodeContent(change)
                          }
                        />
                      ) : null
                    }
                    <div className="w-full flex flex-row justify-between">
                      <FullWidthTextButton text={"Back"} onPress={() => {
                        setSlide('passFields')
                      }} className="w-full" />
                      <DefaultFullWidthButton text={"Next"} onPress={() => {
                        if (!barcodeType || (type === 'generic' && !barcodeContent)) {
                          alert("Please fill out all of the fields.");
                          return;
                        }

                        if (type === 'points') {
                          setSlide('points')
                        } else if (type === 'stamps') {
                          setSlide('stamps')
                        } else {
                          setSlide('confirm')
                        }
                      }} className="w-full" />                 
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
              slide === "points" ? (
                <div className="sm:max-w-md mx-auto min-h-screen flex items-center justify-center">
                  <div className="w-screen md:w-full h-screen sm:h-auto bg-white px-5 pb-7 pt-7 rounded-none sm:rounded-3xl flex flex-col justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
                    <h2 className="text-2xl font-bold mb-1">Setup Your Points System</h2>
                    <p className="text-sm text-gray-400 mb-3">How many points do you want customers to receive?</p>
                    <NumberInput
                      label="How many points do customers earn for every £1 spent?"
                      placeholder="10"
                      value={pointsPerCurrency as unknown as string}
                      onChangeText={(change: string) => setPointsPerCurrency(parseInt(change))}
                      note='Each point is worth £0.01'
                    />
                    <div className="w-full flex flex-row">
                      <FullWidthTextButton text={"Back"} onPress={() => {
                        setSlide('passActivation')
                      }} className="w-full" />
                      <DefaultFullWidthButton text={"Next"} onPress={() => {
                        if (!pointsPerCurrency) {
                          alert("Please enter a number of points per currency.");
                        }

                        setSlide('confirm')
                      }} className="w-full" />                 
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
              slide === "stamps" ? (
                <div className="sm:max-w-md mx-auto min-h-screen flex items-center justify-center">
                  <div className="w-screen md:w-full h-screen sm:h-auto bg-white px-5 pb-7 pt-7 rounded-none sm:rounded-3xl flex flex-col justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
                    <h2 className="text-2xl font-bold mb-1">Setup Your Stamps System</h2>
                    <p className="text-sm text-gray-400 mb-3">How many stamps do you want the customers to earn?</p>
                    <NumberInput
                      label="How many stamps do customers need to earn before they can redeem a free item?"
                      placeholder="10"
                      value={stampsBeforeRedemption as unknown as string}
                      onChangeText={(change: string) => setStampsBeforeRedemption(parseInt(change))}
                      note='minimum 1'
                    />
                    <div className="w-full flex flex-row">
                      <FullWidthTextButton text={"Back"} onPress={() => {
                        setSlide('passActivation')
                      }} className="w-full" />
                      <DefaultFullWidthButton text={"Next"} onPress={() => {
                        if (!pointsPerCurrency) {
                          alert("Please enter a number of stamps that a customer needs to earn.");
                        }

                        setSlide('confirm')
                      }} className="w-full" />                 
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
              slide === "confirm" ? (
                <div className="sm:max-w-md mx-auto min-h-screen flex items-center justify-center">
                  <div className="w-screen md:w-full h-screen sm:h-auto bg-white px-5 pb-7 pt-7 rounded-none sm:rounded-3xl flex flex-col justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
                    <h2 className="text-2xl font-bold mb-1">That's it!</h2>
                    <p className="text-sm text-gray-400 mb-3">Let's finalise everything and create your pass.</p>
                    <div className="w-full flex flex-row">
                      <FullWidthTextButton text={"Back"} onPress={() => {
                        if (type === 'points') {
                          setSlide('pointsConfig')
                        } else if (type === 'stamps') {
                          setSlide('stampsConfig')
                        } else {
                          setSlide('passActivation')
                        }
                      }} className="w-full" />
                      <DefaultFullWidthButton text={"Create Pass"} onPress={createPassFunction} className="w-full" />                 
                    </div>
                  </div>
                </div>
              ) : null
            }
          </div>
        </main>
      </div>
      
    </>
  );
}

export default CreatePassTemplatePage;
