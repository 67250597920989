import axios from 'axios';
import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { DefaultButton, OutlineButton } from '../../../components/Buttons';
import { AdminHeader, MainHeader } from '../../../components/Headers'
import { getToken } from '../../../context/auth';
import QRScanComponent from './qr'

export default function PassScannerPage() {

  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null);

  const [showInfoModal, setShowInfoModal] = useState<boolean>(true);

  const onScan = (result: any) => {

    if (!result || !result.text) {
      setError("No QR code found");
      return;
    }

    axios.get(process.env.REACT_APP_API_URL + `customers/get-customer-by-passcode/${result.text}`, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        'Authorization': getToken() as string,
      }
    }).then(response => {
      navigate(`/customers/${response.data.customerId}`)
    }).catch(() => {
      setError('Customer not found')
    })
  }
  
  return (
    <>
      <div className="min-h-screen h-full bg-gray-50">
        <MainHeader />
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto flex flex-col justify-center items-center py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl tracking-tight font-bold text-gray-900">Scan Wallet Passes</h1>
            <p className="text-xs text-gray-400">Scan any Wallet Pass QR Code!</p>
          </div>
        </header>
        <main className="flex flex-col justify-center items-center">
          <QRScanComponent onScan={(scan: any) => onScan(scan)} />
          {
            error ?
            <p className="text-center text-red-400 text-md mt-2">{ error }</p> : null
          }
        </main>
      </div>
      {
        showInfoModal ?
        <div className="fixed inset-0 z-40 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25">
            <div className="absolute inset-0 bg-gray-500 bg-opacity-25">
              {/* Create a modal element */}
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="max-w-lg w-full px-4 py-3">
                  <div className="flex flex-col justify-between bg-white rounded w-full p-5 drop-shadow">
                    <div className="flex flex-col">
                      <h2 className="text-xl font-semibold mb-3">Important!</h2>
                      <p className="text-gray-600 mb-5">You will only be able to scan passes with the type "identity", "points" and "stamps" using this scanner.</p>
                      <div className="flex flex-row justify-end">
                        <OutlineButton text={"Dismiss"} onPress={() => setShowInfoModal(false)} />
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div> : null
      }
    </>
  )
}
