import React from "react";
import QrReader from "react-qr-scanner";

export default function QRScanComponent(props) {
    return (
      <QrReader
        className={props.className}
        delay={1000}
        
        onError={(error) => props.onError(error)}
        onScan={(data) => props.onScan(data)}
      />
    );
}