import axios from 'axios';
import { useState } from 'react'
import { DefaultButton, DefaultFullWidthButton, FullWidthTextButton, RedFullWidthButton, TextButton } from '../../../components/Buttons'
import { DefaultInput } from '../../../components/Inputs'
import { getToken, resetUserSession, setUserSession } from '../../../context/auth';
import logoImage from '../../../assets/branding/logo.png';

function CreateAccountPage() {

  const [firstNameInput, setFirstNameInput] = useState<string>("");
  const [lastNameInput, setLastNameInput] = useState<string>("");
  const [companyNameInput, setCompanyNameInput] = useState<string>("");
  const [websiteUrlInput, setWebsiteUrlInput] = useState<string>("");

  const [savingChanges, setSavingChanges] = useState<boolean>(false);

  const [showSignOutModal, setshowSignOutModal] = useState<boolean>(false);

  const createAccountFunction = async() => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    
    

    if (!firstNameInput || !lastNameInput || !companyNameInput) {
      alert('Please complete all of the required fields.')
      return;
    }

    

    await axios.post(process.env.REACT_APP_API_URL + "account/create-account", {
      firstName: firstNameInput,
      lastName: lastNameInput,
      companyName: companyNameInput,
      websiteUrl: websiteUrlInput || null,
    }, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin" : "*",
        "Authorization": getToken() as string
      },
    }).then(response => {

      setSavingChanges(false);

      setUserSession(response.data.user, getToken() as string)

      window.location.reload();
      return;

    }).catch(error => {
      setSavingChanges(false);
      try {
        alert(error.response.data.message)
      }
      catch {
        alert("Something went wrong")
      }
    });

  }

  const signOutFunction = () => {

    resetUserSession();
    localStorage.removeItem('refreshToken');
    
    window.location.reload();

  }

  return (
    <>
    <main>
      <div className="max-w-7xl mx-auto m-0 h-screen flex items-center justify-center">
        <div className="max-w-md w-full space-y-8 px-3">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={logoImage}
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-gray-900">
            Create your account
            </h2>
          </div>
          <div>
            <DefaultInput
              label="First Name (required)"
              placeholder="Enter your first name"
              onChangeText={(change: string) => setFirstNameInput(change)}
            />
            <DefaultInput
              label="Last Name (required)"
              placeholder="Enter your last name"
              onChangeText={(change: string) => setLastNameInput(change)}
            />

            <DefaultInput
              label="Company Name (required)"
              placeholder="Enter your company name"
              onChangeText={(change: string) => setCompanyNameInput(change)}
            />

            <DefaultInput
              label="Website URL"
              placeholder="Enter your website url"
              onChangeText={(change: string) => setWebsiteUrlInput(change)}
            />

            <DefaultFullWidthButton
              text={savingChanges ? "Creating Account..." : "Create Account"}
              onPress={createAccountFunction}
              disabled={firstNameInput === "" || lastNameInput === "" || companyNameInput === "" || savingChanges}
            />
            <FullWidthTextButton text='Logout' onPress={() => { setshowSignOutModal(true) }} />
          </div>
        </div>
      </div>
    </main>
    {
      showSignOutModal ? (
        <div className="fixed inset-0 z-40 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25">
            <div className="absolute inset-0 bg-gray-500 bg-opacity-25">
              {/* Create a modal element */}

              <div className="absolute inset-0 flex items-center justify-center">
                <div className="max-w-lg w-full px-4 py-3">
                  <div className="flex flex-col justify-between items-center bg-white rounded w-full p-5 drop-shadow">
                    <div className="flex flex-col">
                      <h2 className="text-xl font-semibold mb-3">Sign Out?</h2>
                      <p className="text-gray-600 mb-5">Are you sure you would like to sign out of your account?</p>
                      <div className="flex flex-row justify-end">
                        <TextButton text="Cancel" onPress={() => { setshowSignOutModal(false) }} />
                        <DefaultButton text="Sign Out" onPress={signOutFunction} className="self-end" />
                      </div>
                      </div>
                    </div>
                  </div>
                </div> 

            </div>
          </div>
        </div>
      ) : null
    }
  </>
  )
}

export default CreateAccountPage;
