import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { DefaultButton, OutlineButton, RedButton } from '../../../components/Buttons';
import { MainHeader } from '../../../components/Headers'
import { getToken } from '../../../context/auth';

interface DynamicLink {
  id: string;
  urlString: string;
  passTemplate: {
    id: string;
    title: string
  } | null
}

function DynamicLinksPage() {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [links, setLinks] = useState<DynamicLink[]>([]);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedDeleteLink, setSelectedDeleteLink] = useState<string | null>(null);
  const [deletingLink, setDeletingLink] = useState<boolean>(false);

  useEffect(() => {
    loadLinks()
  }, [])
  

  const loadLinks = async() => {
    

    // axios post request
    await axios.get(process.env.REACT_APP_API_URL + 'dynamic-links/get-dynamic-links', {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Authorization": await getToken() as string
      },
    }).then((response) => {

      

      const { dynamicLinks } = response.data;

      setLinks(dynamicLinks);
      setIsLoading(false);


    }).catch(error => {
      
      try {
        alert(error.response.data.message)
      }
      catch {
        alert("Something went wrong")
      }
    })
  }

  const deleteLinkFunction = async() => {

    if (!selectedDeleteLink) {
      return;
    }

    axios.post(process.env.REACT_APP_API_URL + 'dynamic-links/delete-dynamic-link', {
      dynamicLinkId: selectedDeleteLink
    },{
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Authorization": await getToken() as string
      },
    }).then((response) => {

      

      setLinks(prev => {
        // remove the deleted link from the list
        return prev.filter(link => link.id !== selectedDeleteLink)
      });
      setShowDeleteModal(false)

      setIsLoading(false);


    }).catch(error => {
      
      try {
        alert(error.response.data.message)
      }
      catch {
        alert("Something went wrong")
      }
    })

  }

  return (
    <>
      <div className="min-h-screen bg-gray-50">
        <MainHeader />
        <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl tracking-tight font-bold text-gray-900">My Links</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            {
              isLoading ? 
              <div className="flex justify-center items-center">
                <p className="text-gray-300">Loading...</p>
              </div> :
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <Link to="/dynamic-links/create-new-link" className="h-40 border-4 border-dashed border-gray-300 rounded-lg flex items-center justify-center hover:opacity-70 hover:cursor-pointer">
                  <h3 className="text-2xl text-gray-300 font-semibold">New Link +</h3>
                </Link>
                {
                  links.map((link: DynamicLink, index: number) => {
                    return (
                      <div key={index} className="bg-white h-40 drop-shadow rounded-lg flex flex-col items-center justify-center border border-transparent shadow-sm text-sm">
                        <div className="w-full h-full flex flex-col items-center justify-center">
                          <h3 className="text-2xl text-gray-900 font-semibold text-center">{ link.urlString }</h3>
                          <p className="text-sm text-gray-600 text-center">Connected Pass: {link.passTemplate?.title || "Pass Template Not Found"}</p>
                          <div className="flex flex-row mt-2">
                            <OutlineButton text="Edit" onPress={() => { navigate(`/dynamic-links/${link.id}/edit-link`) }} />
                            <RedButton text="Delete" onPress={() => { setShowDeleteModal(true); setSelectedDeleteLink(link.id) }} />
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            }
          </div>
        </div>
      </main>
      {
        showDeleteModal ?
        <div className="fixed inset-0 z-40 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25">
            <div className="absolute inset-0 bg-gray-500 bg-opacity-25">
              {/* Create a modal element */}
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="max-w-lg w-full px-4 py-3">
                  <div className="flex flex-col justify-between bg-white rounded w-full p-5 drop-shadow">
                    <div className="flex flex-col">
                      <h2 className="text-xl font-semibold mb-3">Delete Dynamic Link</h2>
                      <p className="text-gray-600 mb-5">Are you sure you want to delete this dynamic link? (This cannot be reversed)</p>
                      <div className="flex flex-row justify-end">
                        <OutlineButton text={"Cancel"} onPress={() => setShowDeleteModal(false)} />
                        <RedButton text={ deletingLink ? "Deleting..." : "Delete"} disabled={deletingLink} onPress={deleteLinkFunction} />
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div> : null
        }
      </div>
    </>
  )
}

export default DynamicLinksPage;
