import React, { useEffect, useState } from "react";
import {
  ActivateButton,
  DefaultButton,
  DefaultFullWidthButton,
  FullWidthTextButton,
  OutlineButton,
  RadioButton,
  RedButton,
  SmallButton,
} from "../../../components/Buttons";
import { AdminHeader, MainHeader } from "../../../components/Headers";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import axios from "axios";
import { getToken } from "../../../context/auth";
import { User } from "../../../models/User";

interface UserAccount {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  websiteUrl: string;
  contactPhone: string;
  contactEmail: string;
  accountStatus: 'active' | 'deleted';
}


export default function AdminUserPage(props: any) {
  let navigate = useNavigate();

  const params = useParams();

  const [showDeleteUserModal, setShowDeleteUserModal] = useState<boolean>(false);
  const [deletingUser, setDeletingUser] = useState<boolean>(false);

  const [userId, setUserId] = useState<string>(params.userId as string);

  const [userLoading, setUserLoading] = useState<boolean>(true);

  const [user, setUser] = useState<UserAccount>({
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    companyName: "",
    websiteUrl: "",
    contactPhone: "",
    contactEmail: "",
    accountStatus: "active",
  })

  useEffect(() => {

    

    loadUser();
  }, []);

  const loadUser = async () => {

    axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/users/get-user`,
        {
          userId: userId,
        },
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: getToken() as string,
          },
        }
      )
      .then((res) => {

        const { user } = res.data;

        

        setUser(user);

        setUserLoading(false);
      })
      .catch((err) => {
        
        alert("Error getting user");
      });
  };

  // const loadCustomers = async () => {

  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL}pass-templates/customers/get-customers`,
  //       {
  //         templateId: passId,
  //       },
  //       {
  //         headers: {
  //           Accept: "application/json, text/plain, */*",
  //           "Content-Type": "application/json",
  //           Authorization: getToken() as string,
  //         },
  //       }
  //     )
  //     .then((res) => {

  //       // get issuing form inputs

  //       setCustomers(
  //         res.data.customers.map((customer: any) => {

  //           return customer;
  //         })
  //       );

  //       // refreshCustomersList(1, res.data.customers)

  //     })
  //     .catch((err) => {
  //       
  //       alert("Error getting pass customers");
  //     });
  // };

  const deleteUserFunction = () => {
    if (user.accountStatus === 'deleted') {
      alert("This user has already been deleted");
      return;
    }

    axios
      .post(
        process.env.REACT_APP_API_URL + "admin/users/delete-user",
        {
          userId: userId,
        },
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: getToken() as string,
          },
        }
      )
      .then((response) => {
        
        setUser(prev => {
          return { ...prev, accountStatus: 'deleted' }
        });
        setShowDeleteUserModal(false);
        alert("User Deleted");
      })
      .catch((error) => {
        
        alert(error.response.data.message);
      });
  };

  return (
    <>
      <div className="min-h-screen h-full bg-gray-50">
        <AdminHeader />
        {
          userLoading ? null :
          <header className="bg-white shadow">
            <div className="flex flex-row justify-between items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl tracking-tight font-bold text-gray-900">
                { user.companyName || user.email }{user.accountStatus === 'deleted' ? ` (DELETED)` : null }
              </h1>
              {
                userLoading ? null : 
                <div className="flex flex-row">
                  {
                    user.accountStatus === 'active' ? 
                    <DefaultButton
                      text={"Edit"}
                      onPress={() => {
                        navigate(`/users/${userId}/edit-user`);
                      }}
                      disabled={false}
                    /> : null
                  }
                  {user.accountStatus !== 'deleted' ? (
                    <RedButton
                      text={"Delete User"}
                      onPress={() => {
                        setShowDeleteUserModal(true);
                      }}
                    />
                  ) : null}
                  
                </div>
              }
            </div>
          </header>
        }
        <main>
          {
            userLoading ?
            <div className="flex h-full w-full justify-center items-center">
              <p className="text-gray-300">Loading...</p>
            </div> :
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              <div className="px-4 py-6 sm:px-0">
                <div className="h-full">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div className="bg-white min-h-80 h-full drop-shadow rounded-lg flex flex-col p-4 hover:opacity-70 hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 border border-transparent shadow-sm text-sm">
                      <h3 className="text-2xl text-gray-900 font-semibold mb-2">
                        Account Info
                      </h3>
                      <p>Email: { user.email || 'N/A' }</p>
                      <p>First Name: { user.firstName || 'N/A' }</p>
                      <p>Last Name: { user.lastName || 'N/A' }</p>
                      <p>Company Name: { user.companyName || 'N/A' }</p>
                      <p>Website URL: { user.websiteUrl || 'N/A' }</p>
                    </div>
                    <div className="bg-white min-h-80 h-full drop-shadow rounded-lg flex flex-col p-4 hover:opacity-70 hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 border border-transparent shadow-sm text-sm">
                      <h3 className="text-2xl text-gray-900 font-semibold mb-2">
                        Contact Info
                      </h3>
                      <p>Phone: { user.contactPhone || 'N/A' }</p>
                      <p>Email: { user.contactEmail || 'N/A' }</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </main>
        {
          showDeleteUserModal ?
          <div className="fixed inset-0 z-40 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25">
            <div className="absolute inset-0 bg-gray-500 bg-opacity-25">
              {/* Create a modal element */}
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="max-w-lg w-full px-4 py-3">
                  <div className="flex flex-col justify-between bg-white rounded w-full p-5 drop-shadow">
                    <div className="flex flex-col">
                      <h2 className="text-xl font-semibold mb-3">Delete User</h2>
                      <p className="text-gray-600 mb-5">Are you sure you want to delete this user? (This cannot be reversed)</p>
                      <div className="flex flex-row justify-end">
                        <OutlineButton text={"Cancel"} onPress={() => setShowDeleteUserModal(false)} />
                        <RedButton text={ deletingUser ? "Deleting..." : "Delete"} disabled={deletingUser} onPress={deleteUserFunction} />
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div> : null
        }
      </div>
    </>
  );
}
