import { useEffect, useState } from 'react';
import logo from './assets/branding/logo-white.png'
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";


import { library } from '@fortawesome/fontawesome'

import SignInPage from './pages/main/auth/sign-in';
import CreateAccountPage from './pages/main/auth/create-account';
import PassesPage from './pages/main/passes';
import CreateNewPassPage from './pages/main/passes/create-new-pass';
import EditAccountPage from './pages/main/account/edit-account';
import SignUpPage from './pages/main/auth/sign-up';
import EditPassPage from './pages/main/passes/edit-pass';
import { getRefreshToken, resetUserSession, setUserSession } from './context/auth';
import axios from 'axios';
import HomePage from './pages/main/other/home';
import ProtectedRoutes from './ProtectedRoutes';
import NotLoggedInRoutes from './NotLoggedInRoutes';
import { User } from './models/User';
import ForgotPasswordPage from './pages/main/auth/forgot-password';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import PassPage from './pages/main/passes/pass';
import UserProtectedRoutes from './UserProtectedRoutes';
import AdminProtectedRoutes from './AdminProtectedRoutes';
import AdminSignInPage from './pages/admin/auth/sign-in';
import AdminUsersPage from './pages/admin/users';
import AdminUserPage from './pages/admin/users/user';
import AdminEditUserPage from './pages/admin/users/edit-user';
import AdminCreateUserPage from './pages/admin/users/create-user';
import CreateNewDynamicLinkPage from './pages/main/dynamicLinks/create-new-link';
import EditDynamicLinkPage from './pages/main/dynamicLinks/edit-link';
import DynamicLinksPage from './pages/main/dynamicLinks';
import CustomerPage from './pages/main/customers/customer';
import PassScannerPage from './pages/main/other/pass-scanner';
import CreatePassTemplatePage from './pages/main/passes/create-template';

function App() {

  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  
  useEffect(() => {
    
    library.add(faCheck as any, faXmark as any)
    
    
    
    
    const refreshToken = getRefreshToken();
    
    if (refreshToken === 'undefined' || refreshToken === undefined || refreshToken === null) {
      setIsAuthenticating(false);
      setCurrentUser(null);
      
      return;
    }
    
    const requestConfig = {
      headers: {
        'Authorization': `${refreshToken}`
      },
    }
    
    // setTimeout(() => {
      axios.get(process.env.REACT_APP_API_URL + 'auth/verify', requestConfig).then(response => {
  
        
  
        setUserSession(response.data.user, response.data.accessToken);
        
        setCurrentUser(response.data.user);

        setIsAuthenticating(false);
      }).catch(() => {
        resetUserSession();
        setCurrentUser(null);
        setIsAuthenticating(false);
      })
      
    // }, 1000);


  }, []);

  if (isAuthenticating) {
    return (
      <div className="bg-gray-800 h-screen w-screen flex flex-col justify-center items-center">
        <img src={logo} className="h-10 mb-4" alt="logo" />
        <p className="text-white">
          Authorising...
        </p>
      </div>
    );
  }

  

  if (currentUser !== null && currentUser !== undefined && currentUser.accountType === 'user' && (!currentUser.accountInfo.companyName || !currentUser.accountInfo.firstName || !currentUser.accountInfo.lastName)) {
    return <CreateAccountPage />
  } 

  return (
    <BrowserRouter>
        <Routes>
          <Route element={<ProtectedRoutes currentUser={currentUser} />}>
            <Route element={<AdminProtectedRoutes isAdmin={currentUser && currentUser.accountType === 'admin' ? true : false} />}>
            <Route path="*" element={<Navigate to="/users" />} />
              <Route path="/users" >
                <Route path="" element={<AdminUsersPage />} />
                <Route path=":userId" >
                  <Route path="" element={<AdminUserPage />} />
                  <Route path="edit-user" element={<AdminEditUserPage />} />
                </Route>
                <Route path="create-user" element={<AdminCreateUserPage />} />
                {/* <Route path="create-new-pass" element={<CreateNewPassPage />} /> */}
              </Route>
            </Route>
            <Route element={<UserProtectedRoutes isUser={currentUser && currentUser.accountType === 'user' ? true : false} />}>
              <Route path="*" element={<Navigate to="/passes" />} />
              <Route path="/passes" >
                <Route path="" element={<PassesPage />} />
                <Route path=":passId" >
                  <Route path="" element={<PassPage />} />
                  <Route path="edit-pass" element={<EditPassPage />} />
                </Route>
                <Route path="create-new-pass" element={<CreatePassTemplatePage />} />
              </Route>
              <Route path="/customers" >
                <Route path=":customerId" element={<CustomerPage />} />
              </Route>
              <Route path="/dynamic-links" >
                <Route path="" element={<DynamicLinksPage />} />
                <Route path=":linkId" >
                  {/* <Route path="" element={<D />} /> */}
                  <Route path="edit-link" element={<EditDynamicLinkPage />} />
                </Route>
                <Route path="create-new-link" element={<CreateNewDynamicLinkPage />} />
              </Route>
              <Route path="/account" >
                <Route path="edit-account" element={<EditAccountPage />} />
                <Route path="edit-plan" /> // TODO: implement edit plan page
                <Route path="select-plan" /> // TODO: implement select plan page
              </Route>
              <Route path="/scan-passes" element={<PassScannerPage />} />
            </Route>
          </Route>
          <Route element={<NotLoggedInRoutes currentUser={currentUser} />}>
            <Route path="*" element={<Navigate to="/auth/sign-in" />} /> // TODO: implement home page
            <Route path="/auth">
              <Route path="sign-in" element={<SignInPage />} />
              <Route path="forgot-password" >
                <Route path="" element={<ForgotPasswordPage />} /> // TODO: implement forgot password page
                <Route path=":resetId" /> // TODO: implement reset password page
              </Route>
              <Route path="sign-up" element={<SignUpPage />} />
              <Route path="admin-sign-in" element={<AdminSignInPage />} />
            </Route>
          </Route>
        </Routes> 
    </BrowserRouter>
  );
}

export default App;
