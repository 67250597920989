import React from "react";
import { Outlet } from "react-router";
import { Navigate } from "react-router-dom";

const useAuth = () => {

  const user = { loggedIn: false };
  return user && user.loggedIn

  // const [user, setUser] = useState(null);
  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged((user) => {
  //     if (user) {
  //       setUser(user);
  //     } else {
  //       setUser(null);
  //     }
  //   });
  //   return unsubscribe;
  // }, []);
  // return { user };
}

interface ProtectedRoutesAttributes {
  currentUser: any
}

const ProtectedRoutes = (props: ProtectedRoutesAttributes) => {

  return props.currentUser ? <Outlet /> : <Navigate to="/auth/sign-in" />;

  // return (
  //   <Switch>
  //     <Route path="/dashboard" component={Dashboard} />
  //     <Route path="/profile" component={Profile} />
  //   </Switch>
  // );
}

export default ProtectedRoutes;