import axios from "axios";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DefaultFullWidthButton, TextButton, OutlineButton, DefaultButton } from "../../../components/Buttons";
import {
  DefaultInput,
  OtpInput,
  PasswordInput,
  TelephoneInput,
} from "../../../components/Inputs";
import { setUserSession } from "../../../context/auth";
import { emailValidator } from "../../../lib/emailValidator";
import { passwordValidator } from "../../../lib/passwordValidator";
import { timeSinceOtpSentInSeconds } from "../../../lib/timeSinceOtpSentInSeconds";
import { User } from "../../../models/User";
import logoImage from '../../../assets/branding/logo.png';

function SignUpPage() {

  const navigate = useNavigate();

  const [emailInput, setEmailInput] = useState<string>("");
  const [passwordInput, setPasswordInput] = useState<string>("");
  const [confirmPasswordInput, setConfirmPasswordInput] = useState<string>("");

  const [policiesCheckbox, setPoliciesCheckbox] = useState<"checked" | "disabled">("disabled");

  const [otpLastSent, setOtpLastSent] = useState<Date>(new Date());
  const [otpInput, setOtpInput] = useState<string>("")

  const [showOtpModal, setShowOtpModal] = useState<boolean>(false);

  const [signingUp, setSigningUp] = useState<boolean>(false);
  const [checkingOtp, setCheckingOtp] = useState<boolean>(false);

  const signUpFunction = async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    

    if (policiesCheckbox !== 'checked' || !emailInput || !emailValidator(emailInput) || !passwordInput || !passwordValidator(passwordInput) || !confirmPasswordInput) {
      setShowOtpModal(false);
      alert("Please fill out all of the required fields.");
      return;
    }

    if (passwordInput !== confirmPasswordInput) {
      setShowOtpModal(false);
      alert("Passwords do not match.");
      return;
    }

    setSigningUp(true);

    

    await axios.post(process.env.REACT_APP_API_URL + "auth/sign-up", {
      email: emailInput,
      password: passwordInput,
    }, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    }).then(response => {
      setOtpLastSent(new Date())
      setShowOtpModal(true)
      setSigningUp(false);
      return;

    }).catch(error => {

      

      setShowOtpModal(false);
      setSigningUp(false);
      try {
        alert(error.response.data.message)
      }
      catch {
        alert("Something went wrong")
      }
      return;
    });

  };

  const confirmOtpFunction = async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    

    if (!otpInput || otpInput.length !== 6) {
      alert('Please input a valid OTP code')
      return;
    }

    setCheckingOtp(true);

    

    await axios.post(process.env.REACT_APP_API_URL + "auth/confirm-otp-code", {
      email: emailInput,
      otpCode: otpInput,
    }, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    }).then(response => {
      setShowOtpModal(true)
      setCheckingOtp(false);

      // add token to local storage

      const { refreshToken, accessToken, user } = response.data;
      localStorage.setItem("refreshToken", refreshToken);
      
      setUserSession(user, accessToken);

      window.location.href = '/passes'
      return;
    }).catch(error => {
      alert(error.response.data.message);
      setCheckingOtp(false);
      return;
    });
  };

  const resendOtpFunction = async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    

    if (!emailInput) {
      alert('Please re-enter your email')
      return;
    }

    if (!otpLastSent || timeSinceOtpSentInSeconds(otpLastSent) < (process.env.REACT_APP_OTP_RESEND_TIME_IN_SECONDS as unknown as number)) {
      alert('Please wait ' + (process.env.REACT_APP_OTP_RESEND_TIME_IN_SECONDS as unknown as number) + ' seconds before resending')
      return;
    }

    

    await axios.post(process.env.REACT_APP_API_URL + "account/request-new-otp-code", {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: emailInput,
      }),
    }).then(response => {
      setOtpLastSent(new Date())
      return;
    }).catch(error => {
      return;
    });
  };

  return (
    <>
      <main>
        <div className="max-w-7xl mx-auto m-0 h-screen flex items-center justify-center">
          <div className="max-w-md w-full space-y-8 px-3">
            <div>
              <img
                className="mx-auto h-12 w-auto"
                src={logoImage}
                alt="Workflow"
              />
              <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-gray-900">
                Sign up for an account
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                or
                <Link
                  to="/auth/sign-in"
                  className="font-medium text-emerald-600 hover:text-emerald-500"
                >
                  {" "}
                  sign in
                </Link>
              </p>
            </div>
            <div>
              <DefaultInput
                label="Email"
                placeholder="Enter your email"
                onChangeText={(change: string) => setEmailInput(change)}
              />
              <PasswordInput
                label="Password"
                placeholder="Enter your password"
                onChangeText={(change: string) => setPasswordInput(change)}
              />

              <PasswordInput
                label="Confirm Password"
                placeholder="Re-enter your password"
                onChangeText={(change: string) => setConfirmPasswordInput(change)}
              />

              <div className="form-check mb-5 flex flex-row">
                <input
                  className="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-gray-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  id="flexCheckDefault"
                  onChange={(change: any) => {
                    setPoliciesCheckbox(
                      change.target.checked === true ? "checked" : "disabled"
                    );
                  }}
                />
                <label className="form-check-label inline-block text-gray-600">
                  I accept the{" "}
                  <a href="https://walletpass.co.uk/terms-conditions" target="_blank" className="underline">
                    terms & conditions
                  </a>{" "}
                  and{" "}
                  <a href="https://walletpass.co.uk/privacy-policy" target="_blank" className="underline">
                    privacy policies
                  </a>
                  .
                </label>
              </div>

              <DefaultFullWidthButton
                text={signingUp ? "Signing Up..." : "Continue"}
                onPress={signUpFunction}
                disabled={policiesCheckbox !== 'checked' || !emailInput || !emailValidator(emailInput) || !passwordInput || !passwordValidator(passwordInput) || !confirmPasswordInput || passwordInput !== confirmPasswordInput || signingUp}
              />
            </div>
          </div>
        </div>
        {
          showOtpModal ? (
            // create a modal for 6 digit otp verification
            <div className="fixed inset-0 z-40 flex items-center justify-center">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-25">
                <div className="absolute inset-0 bg-gray-500 bg-opacity-25">
                  {/* Create a modal element */}

                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="max-w-lg w-full px-4 py-3">
                      <div className="flex flex-col justify-between items-center bg-white rounded w-full p-5 drop-shadow">
                        <div className="flex flex-col">
                          <h2 className="text-xl font-semibold mb-3">Confirm your email</h2>
                          <p className="text-gray-600 mb-5">We've sent a confirmation code to your email address. Please enter it below to continue.</p>
                          <OtpInput placeholder="XXXXXX" className="w-full" onChangeText={(change: string) => setOtpInput(change)} />
                          <div className="flex flex-row justify-end">
                            <TextButton text="Cancel" onPress={() => { setShowOtpModal(false) }} />
                            <OutlineButton text={"Resend in " + ('')} onPress={resendOtpFunction} disabled={
                              // disable resend button if otp has been sent less than 30 seconds ago
                              (timeSinceOtpSentInSeconds(otpLastSent as Date) < (process.env.REACT_APP_OTP_RESEND_TIME_IN_SECONDS as unknown as number)) ? true : false
                            } />
                            <DefaultButton text={checkingOtp ? "Checking Code..." : "Confirm Code"} onPress={confirmOtpFunction} className="self-end" disabled={otpInput.length !== 6 || checkingOtp} />
                          </div>
                         </div>
                        </div>
                      </div>
                    </div> 

                </div>
              </div>
            </div>
          ) : null
        }
      </main>
    </>
  );
}

export default SignUpPage;
