import axios from "axios";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  DefaultButton,
  DefaultFullWidthButton,
  FullWidthTextButton,
} from "../../../components/Buttons";
import {
  DefaultInput,
  OtpInput,
  PasswordInput,
  TelephoneInput,
} from "../../../components/Inputs";
import { setUserSession } from '../../../context/auth';
import { User } from "../../../models/User";
import logoImage from '../../../assets/branding/logo.png';
 
function SignInPage() {

  const navigate = useNavigate();

  const [emailInput, setEmailInput] = useState<string>("");
  const [passwordInput, setPasswordInput] = useState<string>("");

  const [signingIn, setSigningIn] = useState<boolean>(false);

  const signInFunction = async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    

    if (!emailInput || !passwordInput) {
      return;
    }

    setSigningIn(true);

    

    await axios.post(process.env.REACT_APP_API_URL + "auth/login", {
      email: emailInput,
      password: passwordInput,
    }, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    }).then(response => {
      const { refreshToken, accessToken, user } = response.data;


      setSigningIn(false);

      localStorage.setItem("refreshToken", refreshToken);

      // set user session

      setUserSession(accessToken, user);

      window.location.href = '/passes'
      return;

    }).catch(error => {
      


      setSigningIn(false);

      try {
        alert(error.response.data.message)
      }
      catch {
        alert("Something went wrong")
      }

      return;
    });
  };

  return (
    <>
      <main>
        <div className="max-w-7xl mx-auto m-0 h-screen w-screen flex items-center justify-center">
          <div className="max-w-md w-full space-y-8 px-3">
            <div>
              <img
                className="mx-auto h-12 w-auto"
                src={logoImage}
                alt="Workflow"
              />
              <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-gray-900">
                Sign in to your account
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                or
                <Link
                  to="/auth/sign-up"
                  className="font-medium text-emerald-600 hover:text-emerald-500"
                >
                  {" "}
                  create an account
                </Link>
              </p>
            </div>
            <div>
              <DefaultInput
                label="Email"
                placeholder="Enter your email"
                onChangeText={(change: string) => setEmailInput(change)}
              />
              <PasswordInput
                label="Password"
                placeholder="Enter your password"
                onChangeText={(change: string) => setPasswordInput(change)}
              />

              <DefaultFullWidthButton
                text={signingIn ? "Signing In..." : "Continue"}
                onPress={signInFunction}
                disabled={!emailInput || !passwordInput || signingIn}
              />
              <FullWidthTextButton text={"Forgot password?"} onPress={() => {
                navigate('/auth/forgot-password')
              }} />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default SignInPage;
