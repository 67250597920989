import React from "react";
import { Outlet } from "react-router";
import { Navigate } from "react-router-dom";
import { User } from "./models/User";
import SignInPage from "./pages/main/auth/sign-in";

interface AdminProtectedRoutesAttributes {
  isAdmin: boolean;
}

const AdminProtectedRoutes = (props: AdminProtectedRoutesAttributes) => {

  return props.isAdmin ? <Outlet /> : <Navigate to="/auth/sign-in" />;

  // return (
  //   <Switch>
  //     <Route path="/dashboard" component={Dashboard} />
  //     <Route path="/profile" component={Profile} />
  //   </Switch>
  // );
}

export default AdminProtectedRoutes;