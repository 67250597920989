import React, { useEffect, useState } from "react";
import {
  ActivateButton,
  DefaultButton,
  DefaultFullWidthButton,
  FullWidthTextButton,
  OutlineButton,
  RadioButton,
  RedButton,
  SmallButton,
} from "../../../components/Buttons";
import { MainHeader } from "../../../components/Headers";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import axios from "axios";
import { getToken } from "../../../context/auth";
import { DefaultInput, NumberInput } from "../../../components/Inputs";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import { formatDateWithTime } from "../../../lib/formatDateWithTime";

interface PassTemplate {
  id: string;
  title: string;
  type: 'generic' | 'points' | 'stamps' | 'savings';
  pointsLimit: number | null;
  pointsPerCurrency: number | null;
}

interface CustomerAccountInfo {
  firstName: string | null;
  lastName: string | null;
  title: string | null;
  companyName: string | null;
  phone: string | null;
  dateOfBirth: Date | string | null;
  address: CustomerAddress | null;
}

interface CustomerAddress {
  line1: string | null;
  line2: string | null;
  city: string | null;
  county: string | null;
  postcode: string | null;
  country: string | null;
}

interface CustomerAuthentication {
  emailAddress: string | null;
  secondaryEmailAddress: string | null;
}

interface Transaction {
  id: string;
  created: Date;
  amount: number;
  notes: string;
  void: boolean;
}

export default function CustomerPage(props: any) {
  let navigate = useNavigate();

  const params = useParams();

  const [customerId, setCustomerId] = useState<string>(params.customerId as string);

  const [customerLoading, setCustomerLoading] = useState<boolean>(true);

  const [accountInfo, setAccountInfo] = useState<CustomerAccountInfo>({
    firstName: null,
    lastName: null,
    title: null,
    companyName: null,
    phone: null,
    dateOfBirth: null,
    address: null,
  });

  const [authentication, setAuthentication] = useState<CustomerAuthentication>({
    emailAddress: null,
    secondaryEmailAddress: null,
  });

  const [balance, setBalance] = useState<number>(0);

  const [otherInfo, setOtherInfo] = useState<any>(null);

  const [passTemplate, setPassTemplate] = useState<PassTemplate>({
    id: '',
    title: '',
    type: 'generic',
    pointsLimit: 0,
    pointsPerCurrency: 0,
  })

  const [createdAt, setCreatedAt] = useState<Date>(new Date());

  const [showAddPointsModal, setShowAddPointsModal] = useState<boolean>(false);
  const [addingPoints, setAddingPoints] = useState<boolean>(false);

  const [pointsToAdd, setPointsToAdd] = useState<{
    value: number;
    notes: string;
  }>({
    value: 0,
    notes: '',
  });

  const [showUsePointsModal, setShowUsePointsModal] = useState<boolean>(false);
  const [usingPoints, setUsingPoints] = useState<boolean>(false);

  const [pointsToUse, setPointsToUse] = useState<{
    value: number;
    notes: string;
  }>({
    value: 0,
    notes: '',
  });

  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [voidingTransaction, setVoidingTransaction] = useState<boolean>(false);
  const [pendingVoidTransactionId, setPendingVoidTransactionId] = useState<string>('');

  const [showVoidTransactionModal, setShowVoidTransactionModal] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      loadCustomer();
      
    }, 2000);
  }, []);

  const loadCustomer = async () => {

    axios
      .get(
        `${process.env.REACT_APP_API_URL}customers/${customerId}`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: getToken() as string,
          },
        }
      )
      .then((res) => {

        const {
          accountInfo,
          authentication,
          balance,
          otherInfo,
          passTemplate,
          transactions,
          createdAt,
        } = res.data;

        setAccountInfo(accountInfo);
        setAuthentication(authentication);
        setBalance(balance);
        setOtherInfo(otherInfo);
        setPassTemplate(passTemplate);
        setTransactions(transactions);
        setCreatedAt(createdAt);
        setCustomerLoading(false);
      })
      .catch((err) => {
        alert("Error getting customer account");
      });
  };

  const addPointsFunction = async () => {

    if (!pointsToAdd.value || pointsToAdd.value <= 0) {
      alert("Please enter a value to add");
      return;
    }
    
    setAddingPoints(true);

    let formattedPointsToAdd = pointsToAdd.value;

    if (passTemplate.type === 'points') {
      formattedPointsToAdd = ((pointsToAdd.value) * (passTemplate.pointsPerCurrency || 1));
    }

    if (passTemplate.type === 'savings') {
      formattedPointsToAdd = ((pointsToAdd.value) * 100);
    }

    axios.post(process.env.REACT_APP_API_URL + 'transactions/record-transaction', {
      customerId: customerId,
      amount: formattedPointsToAdd,
      notes: pointsToAdd.notes,
    }, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: getToken() as string,
      },
    }).then((response) => {
      setShowAddPointsModal(false);
      setPointsToAdd({
        value: 0,
        notes: '',
      });

      const newTransaction = {
        id: response.data.id,
        created: new Date(),
        amount: formattedPointsToAdd,
        notes: pointsToAdd.notes,
        void: false,
      }

      setTransactions([newTransaction, ...transactions]);

      setBalance(balance as number + Number(formattedPointsToAdd));
      setAddingPoints(false);
    }).catch((error) => {
      alert("Error adding points");
      setAddingPoints(false);
    })

  }

  const usePointsFunction = async () => {

    if (!pointsToUse.value || pointsToUse.value <= 0) {
      alert("Please enter a value to use");
      return;
    }

    if (Number(pointsToUse.value) > balance || (passTemplate.type === 'points' && ( (pointsToUse.value) * 100 ) > balance)) {
      alert("Insufficient balance");
      return;
    }

    setUsingPoints(true);

    let formattedPointsToUse = pointsToUse.value;

    if (passTemplate.type === 'points') {
      formattedPointsToUse = ((pointsToUse.value) * (100));
    }

    axios.post(process.env.REACT_APP_API_URL + 'transactions/record-transaction', {
      customerId: customerId,
      amount: -formattedPointsToUse,
      notes: pointsToUse.notes,
    }, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: getToken() as string,
      },
    }).then((response) => {

      setPointsToUse({
        value: 0,
        notes: '',
      });
      setBalance(balance as number - Number(formattedPointsToUse));

      // add transaction to front of transactions array

      const newTransaction = {
        id: response.data.id,
        created: new Date(),
        amount: -formattedPointsToUse,
        notes: pointsToUse.notes,
        void: false,
      }

      setTransactions([newTransaction, ...transactions]);
      setUsingPoints(false);
      setShowUsePointsModal(false);
    }).catch((error) => {
      alert("Error using points");
      setUsingPoints(false);
    });
  }

  const voidTransactionFunction = () => {

    if (!pendingVoidTransactionId) {
      console.log('No transaction to void');
      alert("Error voiding transaction");
      return;
    }

    setVoidingTransaction(true);

    axios.delete(process.env.REACT_APP_API_URL + `transactions/void-transaction/${pendingVoidTransactionId}`, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: getToken() as string,
      },
    }).then(response => {

      // change the transaction to void

      const indexOfTransaction = transactions.findIndex((transaction) => transaction.id === pendingVoidTransactionId);

      if (indexOfTransaction > -1) {
        transactions[indexOfTransaction].void = true;
        setTransactions([...transactions]);
      }

      // update customer's balance

      setBalance(balance as number - Number(transactions[indexOfTransaction].amount));

      setShowVoidTransactionModal(false);
      setVoidingTransaction(false);

    }).catch(error => {
      alert("Error voiding transaction");
      setVoidingTransaction(false);
    })
  }

  return (
    <>
      <div className="min-h-screen h-full bg-gray-50">
        <MainHeader />
        {
          customerLoading ? null :
          <header className="bg-white shadow">
            <div className="flex flex-row justify-between items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <div className="flex flex-col items-start">
                <h1 className="text-3xl tracking-tight font-bold text-gray-900">
                  { accountInfo.firstName ? accountInfo.firstName : ''} { accountInfo.lastName ? (accountInfo.firstName ? ' ' + accountInfo.lastName : accountInfo.lastName) : ''}
                </h1>
                {
                  authentication.emailAddress ?
                  <p>{ authentication.emailAddress }</p> : null
                }
              </div>
            </div>
          </header>
        }
        <main>
          {
            customerLoading ?
            <div className="flex h-screen w-screen justify-center items-center">
              <p className="text-gray-300">Loading...</p>
            </div>  :
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <div className="h-full">
                  {
                    passTemplate.type === 'points' || passTemplate.type === 'stamps' || passTemplate.type === 'savings' ?
                    <div className="bg-white min-h-80 h-full drop-shadow rounded-lg flex flex-row items-center justify-between p-4 border border-transparent shadow-sm text-sm mb-5">
                      <div>
                        {
                          passTemplate.type === 'points' ?
                          <h3 className="text-2xl text-gray-900 font-semibold">
                            Points: { balance }
                          </h3> : null
                        }
                        {
                          passTemplate.type === 'stamps' ?
                          <h3 className="text-2xl text-gray-900 font-semibold">
                            Stamps: { balance }
                          </h3> : null
                        }
                        {
                          passTemplate.type === 'savings' ?
                          <h3 className="text-2xl text-gray-900 font-semibold">
                            Money Saved: £{ (balance / 100).toFixed(2) }
                          </h3> : null
                        }
                        {
                          passTemplate.type === 'points' ?
                          <p className="text-gray-400 text-xs mt-1">Value: £{balance / 100}</p> : null
                        }
                        {
                          passTemplate.pointsLimit ?
                          <p className="text-gray-400 text-xs mt-1">Limit: {passTemplate.pointsLimit}</p> : null
                        }
                      </div>
                      <div className="flex flex-row ">
                        {
                          passTemplate.type === 'points' || passTemplate.type === 'stamps' ?
                          <OutlineButton
                            text={`Use ${passTemplate.type === 'stamps' ? 'Stamps' : 'Points'}`}
                            onPress={() => setShowUsePointsModal(true)}
                            disabled={balance <= 0}
                          /> : null
                        }
                        {
                          passTemplate.type === 'points' ?
                          <DefaultButton
                            text={`Add Points`}
                            onPress={() => setShowAddPointsModal(true)}
                            disabled={passTemplate.pointsLimit && balance >= passTemplate.pointsLimit ? true : false}
                          /> : null
                        }
                        {
                          passTemplate.type === 'stamps' ?
                          <DefaultButton
                            text={`Add Stamps`}
                            onPress={() => setShowAddPointsModal(true)}
                            disabled={passTemplate.pointsLimit && balance >= passTemplate.pointsLimit ? true : false}
                          /> : null
                        }
                        {
                          passTemplate.type === 'savings' ?
                          <DefaultButton
                            text={`Add Money Saved`}
                            onPress={() => setShowAddPointsModal(true)}
                            disabled={passTemplate.pointsLimit && balance >= passTemplate.pointsLimit ? true : false}
                          /> : null
                        }
                      </div>
                    </div> : null
                  }
                  <div className="bg-white min-h-80 h-full drop-shadow rounded-lg flex flex-col p-4 border border-transparent shadow-sm text-sm mb-5">
                    <h3 className="text-2xl text-gray-900 font-semibold mb-2">
                      Account Info
                    </h3>
                    <p className="text-gray-400 text-xs">Here's all of the account info we have on the customer. You can collect more data by adding additional input fields to your issuing form for your pass template.</p>
                    <table className="mt-5">
                      <tbody>

                        <tr>
                          <td className="text-gray-500">First Name</td>
                          <td className="text-gray-900 font-semibold">{ accountInfo.firstName ? accountInfo.firstName : 'Not found' }</td>
                        </tr>
                        <tr>
                          <td className="text-gray-500">Last Name</td>
                          <td className="text-gray-900 font-semibold">{ accountInfo.lastName ? accountInfo.lastName : 'Not found' }</td>
                        </tr>
                        <tr>
                          <td className="text-gray-500">Title</td>
                          <td className="text-gray-900 font-semibold">{ accountInfo.title ? accountInfo.title : 'Not found' }</td>
                        </tr>
                        <tr>
                          <td className="text-gray-500">Company Name</td>
                          <td className="text-gray-900 font-semibold">{ accountInfo.companyName ? accountInfo.companyName : 'Not found' }</td>
                        </tr>
                        <tr>
                          <td className="text-gray-500">Phone</td>
                          <td className="text-gray-900 font-semibold">{ accountInfo.phone ? accountInfo.phone : 'Not found' }</td>
                        </tr>
                        <tr>
                          <td className="text-gray-500">Date of Birth</td>
                          <td className="text-gray-900 font-semibold">{ accountInfo.dateOfBirth ? (  new Date(accountInfo.dateOfBirth).toDateString()) : 'Not found' }</td>
                        </tr>
                        <tr>
                          <td className="text-gray-500">Address</td>
                          <td className="text-gray-900 font-semibold">
                            {
                              accountInfo.address ?
                              <>
                                { accountInfo.address.line1 ? accountInfo.address.line1 : '' }<br />
                                { accountInfo.address.line2 ? accountInfo.address.line2 : '' }<br />
                                { accountInfo.address.city ? accountInfo.address.city : '' }<br />
                                { accountInfo.address.country ? accountInfo.address.country : '' }<br />
                              </> : 'Not found'
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {
                    otherInfo !== null && Object.keys(otherInfo).length > 0 ?
                    <div className="bg-white min-h-80 h-full drop-shadow rounded-lg flex flex-col p-4 border border-transparent shadow-sm text-sm mb-5">
                      <h3 className="text-2xl text-gray-900 font-semibold mb-2">
                        Other Info
                      </h3>
                      <p className="text-gray-400 text-xs">Here's all of the additional info that we have on the customer. You can collect more data by adding additional input fields to your issuing form for your pass template.</p>
                      <table className="mt-5">
                        <tbody>
                          {/* Create a map of the otherInfo object and its keys */}
                          {
                            Object.keys(otherInfo).map((key, index) => {
                              return (
                                <tr key={index}>
                                  <td className="text-gray-500">{ key }</td>
                                  <td className="text-gray-900 font-semibold">{ otherInfo[key] }</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div> : null
                  }
                  <div className="bg-white min-h-80 h-full drop-shadow rounded-lg flex flex-col p-4 border border-transparent shadow-sm text-sm mb-5">
                    <h3 className="text-2xl text-gray-900 font-semibold mb-2">
                      Recent Transactions
                    </h3>
                    <p className="text-gray-400 text-xs">Here are all of the customer's transactions for the past week.</p>
                    {
                      transactions.length > 0 ?
                      <table className="mt-5">
                        <thead>
                          <tr>
                            <th className="text-gray-500">Amount</th>
                            <th className="text-gray-500">Date</th>
                            <th className="text-gray-500">Notes</th>
                            <th className="text-gray-500 text-center">Void</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* Create a map of the otherInfo object and its keys */}
                          {
                            transactions.map((transaction: Transaction, indexOfTransaction: number) => {
                              return (
                                <tr key={indexOfTransaction}>
                                  <td className="text-gray-900 text-center font-semibold">{ passTemplate.type === 'savings' ? `£${ (transaction.amount / 100).toFixed(2) }` : transaction.amount }</td>
                                  <td className="text-gray-500 text-center">{ transaction.created ? formatDateWithTime(new Date(transaction.created)) : 'Not Found' }</td>
                                  <td className="text-gray-500 text-center">{ transaction.notes ? transaction.notes : 'Not Found' }</td>
                                  <td className="text-center">
                                    <RedButton text={transaction.void ? 'Already Void' : 'Void'} disabled={transaction.void} onPress={() => {
                                      setPendingVoidTransactionId(transaction.id)
                                      setShowVoidTransactionModal(true);
                                    }} />
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table> : <p className="text-gray-500 mt-4">No transactions found.</p>
                    }
                  </div>
                </div>
            </div>
          }
        </main>
      </div>
      {
        showAddPointsModal ?
        <div className="fixed inset-0 z-40 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25">
            <div className="absolute inset-0 bg-gray-500 bg-opacity-25">
              {/* Create a modal element */}

              <div className="absolute inset-0 flex items-center justify-center">
                <div className="max-w-lg w-full px-4 py-3">
                  <div className="flex flex-col justify-between bg-white rounded w-full p-5 drop-shadow">
                    <div className="flex flex-col">
                      {
                        passTemplate.type === 'points' ?
                        <h2 className="text-xl font-semibold mb-1">Add Points</h2> : null
                      }
                      {
                        passTemplate.type === 'stamps' ?
                        <h2 className="text-xl font-semibold mb-1">Add Stamps</h2> : null
                      }
                      {
                        passTemplate.type === 'savings' ?
                        <h2 className="text-xl font-semibold mb-1">Add Money Saved</h2> : null
                      }
                      <p className="text-gray-400 text-xs mb-3">Add {passTemplate.type === 'stamps' ? 'stamps' : (passTemplate.type === 'savings' ? 'savings' : 'points' )} to the customer's account. Make sure you include a reason within the notes if you want this transaction to be identifiable.</p>
                      {
                        passTemplate.type === 'stamps' ?
                        <NumberInput
                          label="How many stamps would you like to add?"
                          value={ pointsToAdd.value.toString() }
                          onChangeText={(value: number) => setPointsToAdd({
                            ...pointsToAdd,
                            value
                          })}
                        /> : null
                      }
                      {
                        passTemplate.type === 'points' ?
                        <NumberInput
                          label="How much did the customer spend? (£)"
                          value={ pointsToAdd.value.toString() }
                          onChangeText={(value: number) => setPointsToAdd({
                            ...pointsToAdd,
                            value
                          })}
                          note={`Customers earn ${passTemplate.pointsPerCurrency} points per pound spent.`}
                        /> : null
                      }
                      {
                        passTemplate.type === 'savings' ?
                        <NumberInput
                          label="How much did the customer save? (£)"
                          value={ pointsToAdd.value.toString() }
                          onChangeText={(value: number) => setPointsToAdd({
                            ...pointsToAdd,
                            value
                          })}
                        /> : null
                      }
                      <DefaultInput
                        label="Notes"
                        value={ pointsToAdd.notes }
                        onChangeText={(value: string) => setPointsToAdd({
                          ...pointsToAdd,
                          notes: value
                        })}
                      />
                      <div className="flex flex-row justify-end">
                        <OutlineButton text={"Cancel"} onPress={() => setShowAddPointsModal(false)} />
                        <DefaultButton text={ addingPoints ? `Adding ${passTemplate.type === 'stamps' ? 'Stamps' : (passTemplate.type === 'savings' ? 'Savings' : 'Points')}...` : `Add ${passTemplate.type === 'stamps' ? 'Stamps' : (passTemplate.type === 'savings' ? 'Savings' : 'Points')}`} disabled={addingPoints || pointsToAdd.value <= 0 || (passTemplate.pointsLimit ? (passTemplate.pointsLimit < (balance + Number(pointsToAdd.value))) : false)  } onPress={addPointsFunction} />
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div> : null
      }
      {
        showUsePointsModal ?
        <div className="fixed inset-0 z-40 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25">
            <div className="absolute inset-0 bg-gray-500 bg-opacity-25">
              {/* Create a modal element */}

              <div className="absolute inset-0 flex items-center justify-center">
                <div className="max-w-lg w-full px-4 py-3">
                  <div className="flex flex-col justify-between bg-white rounded w-full p-5 drop-shadow">
                    <div className="flex flex-col">
                      <h2 className="text-xl font-semibold mb-1">Use {passTemplate.type === 'stamps' ? 'Stamps' : 'Points'}</h2>
                      <p className="text-gray-400 text-xs mb-3">Use the customer's {passTemplate.type === 'stamps' ? 'stamps' : 'points'}. Make sure you include a reason within the notes if you want this transaction to be identifiable.</p>
                      <p className="text-gray-500 mb-5">Customer Balance: £{balance / 100}</p>
                      {
                        passTemplate.type === 'stamps' ?
                        <NumberInput
                          label="Amount (min 1)"
                          value={ pointsToUse.value.toString() }
                          onChangeText={(value: number) => setPointsToUse({
                            ...pointsToUse,
                            value
                          })}
                        /> :
                        <NumberInput
                          label="How much did the customer spend? (£)"
                          value={ pointsToUse.value.toString() }
                          onChangeText={(value: number) => setPointsToUse({
                            ...pointsToUse,
                            value
                          })}
                        />
                      }
                      <DefaultInput
                        label="Notes"
                        value={ pointsToUse.notes }
                        onChangeText={(value: string) => setPointsToUse({
                          ...pointsToUse,
                          notes: value
                        })}
                      />
                      <div className="flex flex-row justify-end">
                        <OutlineButton text={"Cancel"} onPress={() => setShowUsePointsModal(false)} />
                        <DefaultButton text={ usingPoints ? `Using ${passTemplate.type === 'stamps' ? 'Stamps' : 'Points'}...` : `Use ${passTemplate.type === 'stamps' ? 'Stamps' : 'Points'}`} disabled={usingPoints || pointsToUse.value <= 0 || pointsToUse.value > balance || (passTemplate.type === 'points' && ((pointsToUse.value * 100) > balance))} onPress={usePointsFunction} />
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div> : null
      }
      {
          showVoidTransactionModal ?
          <div className="fixed inset-0 z-40 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25">
            <div className="absolute inset-0 bg-gray-500 bg-opacity-25">
              {/* Create a modal element */}
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="max-w-lg w-full px-4 py-3">
                  <div className="flex flex-col justify-between bg-white rounded w-full p-5 drop-shadow">
                    <div className="flex flex-col">
                      <h2 className="text-xl font-semibold mb-3">Void Transaction</h2>
                      <p className="text-gray-600 mb-5">Are you sure you want to void this transaction? (This cannot be reversed)</p>
                      <div className="flex flex-row justify-end">
                        <OutlineButton text={"Cancel"} onPress={() => setShowVoidTransactionModal(false)} />
                        <RedButton text={ voidingTransaction ? "Voiding Transaction..." : "Void"} disabled={voidingTransaction} onPress={voidTransactionFunction} />
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div> : null
        }
    </>
  );
}
