import React from 'react'

interface DefaultInputProps {
  value?: string,
  label?: string,
  placeholder?: string,
  onChangeText?: any,
  className?: string,
  note?: string,
  maxLength?: number,
}

export function DefaultInput(props: DefaultInputProps) {
  return (
    <div className={props.className}>
      <label className="relative block mb-5 text-left">
        {
          props.label ?
          <span className="ml-3 text-sm text-gray-600">{ props.label }</span> : null
        }
        <input
          type="text"
          className="mt-1 border-box placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-3 shadow-sm text-gray-900 focus:outline-none focus:border-gray-500 focus:ring-gray-500 focus:ring-1 sm:text-sm"
          placeholder={props.placeholder}
          name="default"
          onChange={(change: any) => { props.onChangeText(change.target.value) }}
          value={props.value}
          maxLength={props.maxLength}
        />
        {
          props.note ?
          <span className="text-gray-400 text-xs">{ props.note }</span> : null
        }
      </label>
    </div>
  )
}

export function UrlInput(props: DefaultInputProps) {
  return (
    <div className={props.className}>
      <label className="relative block mb-5 text-left">
        {
          props.label ?
          <span className="ml-3 text-sm text-gray-600">{ props.label }</span> : null
        }
        <input
          type="url"
          className="mt-1 border-box placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-3 shadow-sm text-gray-900 focus:outline-none focus:border-gray-500 focus:ring-gray-500 focus:ring-1 sm:text-sm"
          placeholder={props.placeholder}
          name="default"
          onChange={(change: any) => { props.onChangeText(change.target.value) }}
          value={props.value}
          maxLength={props.maxLength}
        />
        {
          props.note ?
          <span className="text-gray-400 text-xs">{ props.note }</span> : null
        }
      </label>
    </div>
  )
}

export function NumberInput(props: DefaultInputProps) {
  return (
    <div className={props.className}>
      <label className="relative block mb-5 text-left">
        {
          props.label ?
          <span className="ml-3 text-sm text-gray-600">{ props.label }</span> : null
        }
        <input
          type="number"
          className="mt-1 border-box placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-3 shadow-sm text-gray-900 focus:outline-none focus:border-gray-500 focus:ring-gray-500 focus:ring-1 sm:text-sm"
          placeholder={props.placeholder}
          name="default"
          onChange={(change: any) => { props.onChangeText(change.target.value) }}
          value={props.value}
          maxLength={props.maxLength}
        />
        {
          props.note ?
          <span className="text-gray-400 text-xs">{ props.note }</span> : null
        }
      </label>
    </div>
  )
}

export function MultilineInput(props: DefaultInputProps) {
  return (
    <div className={props.className}>
      <label className="relative block mb-5 text-left">
        {
          props.label ?
          <span className="ml-3 text-sm text-gray-600">{ props.label }</span> : null
        }
        <textarea
          rows={5}
          className="mt-1 border-box placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-3 shadow-sm text-gray-900 focus:outline-none focus:border-gray-500 focus:ring-gray-500 focus:ring-1 sm:text-sm"
          placeholder={props.placeholder}
          name="multiline"
          onChange={(change: any) => { props.onChangeText(change.target.value) }}
          value={props.value}
          maxLength={props.maxLength}
        ></textarea>
        {
          props.note ?
          <span className="text-gray-400 text-xs">{ props.note }</span> : null
        }
      </label>
    </div>
  )
}

export function PasswordInput(props: DefaultInputProps) {
  return (
    <div className={props.className}>
      <label className="relative block mb-5 text-left">
        {
          props.label ?
          <span className="ml-3 text-sm text-gray-600">{ props.label }</span> : null
        }
        <input
          type="password"
          className="mt-1 placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-3 shadow-sm text-gray-900 focus:outline-none focus:border-gray-500 focus:ring-gray-500 focus:ring-1 sm:text-sm"
          placeholder={props.placeholder}
          name="password"
          onChange={(change: any) => { props.onChangeText(change.target.value) }}
          value={props.value}
          maxLength={props.maxLength}
        />
        {
          props.note ?
          <span className="text-gray-400 text-xs">{ props.note }</span> : null
        }
      </label>
    </div>
  )
}

export function EmailInput(props: DefaultInputProps) {
  return (
    <div className={props.className}>
      <label className="relative block mb-5 text-left">
        {
          props.label ?
          <span className="ml-3 text-sm text-gray-600">{ props.label }</span> : null
        }
        <input
          type="email"
          className="mt-1 placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-3 shadow-sm text-gray-900 focus:outline-none focus:border-gray-500 focus:ring-gray-500 focus:ring-1 sm:text-sm"
          placeholder={props.placeholder}
          name="email"
          onChange={(change: any) => { props.onChangeText(change.target.value) }}
          value={props.value}
          maxLength={props.maxLength}
        />
        {
          props.note ?
          <span className="text-gray-400 text-xs">{ props.note }</span> : null
        }
      </label>
    </div>
  )
}

export function TelephoneInput(props: DefaultInputProps) {
  return (
    <div className={props.className}>
      <label className="relative block mb-5 text-left">
        {
          props.label ?
          <span className="ml-3 text-sm text-gray-600">{ props.label }</span> : null
        }
        <input
          type="text"
          className="mt-1 placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-3 shadow-sm text-gray-900 focus:outline-none focus:border-gray-500 focus:ring-gray-500 focus:ring-1 sm:text-sm"
          placeholder={props.placeholder}
          name="phoneNumber"
          onChange={(change: any) => { props.onChangeText(change.target.value) }}
          value={props.value}
          maxLength={props.maxLength}
        />
        {
          props.note ?
          <span className="text-gray-400 text-xs">{ props.note }</span> : null
        }
      </label>
    </div>
  )
}

export function OtpInput(props: DefaultInputProps) {
  return (
    <div className={props.className}>
      <label className="relative block mb-5 text-left">
        {
          props.label ?
          <span className="ml-3 text-sm text-gray-600">{ props.label }</span> : null
        }
        <input
          type="text"
          maxLength={6}
          className="mt-1 text-center placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-3 shadow-sm text-gray-900 focus:outline-none focus:border-gray-500 focus:ring-gray-500 focus:ring-1 sm:text-sm"
          placeholder={props.placeholder}
          name="otp"
          onChange={(change: any) => { props.onChangeText(change.target.value) }}
          value={props.value}
        />
        {
          props.note ?
          <span className="text-gray-400 text-xs">{ props.note }</span> : null
        }
      </label>
    </div>
  )
}

export function DateInput(props: DefaultInputProps) {
  return (
    <div className={props.className}>
      <label className="relative block mb-5 text-left">
        {
          props.label ?
          <span className="ml-3 text-sm text-gray-600">{ props.label }</span> : null
        }
        <input
          type="date"
          max-length="6"
          className="mt-1 placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-3 shadow-sm text-gray-900 focus:outline-none focus:border-gray-500 focus:ring-gray-500 focus:ring-1 sm:text-sm"
          placeholder={props.placeholder}
          name="otp"
          onChange={(change: any) => { props.onChangeText(change.target.value) }}
          value={props.value}
        />
        {
          props.note ?
          <span className="text-gray-400 text-xs">{ props.note }</span> : null
        }
      </label>
    </div>
  )
}

interface FileInputProps {
  value?: any,
  label?: string,
  accept?: string,
  onChange?: any,
  className?: string,
  id?: string
  note?: string,
}

export function FileInput(props: FileInputProps) {
  return (
    <div className={props.className}>
      <label className="relative block mb-5 text-left">
        {
          props.label ?
          <span className="ml-3 text-sm text-gray-600">{ props.label }</span> : null
        }
        <input
          id={props.id}
          accept={props.accept}
          type="file"
          className="mt-1 placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-3 shadow-sm text-gray-900 focus:outline-none focus:border-gray-500 focus:ring-gray-500 focus:ring-1 sm:text-sm"
          name="file"
          onChange={(change: any) => { props.onChange(change) }}
          value={props.value}
        />
        {
          props.note ?
          <span className="text-gray-400 text-xs">{ props.note }</span> : null
        }
      </label>
    </div>
  )
}

interface ColourInputProps {
  value?: string,
  label?: string,
  onChangeText?: any,
  className?: string
  note?: string,
}

export function ColourInput(props: ColourInputProps) {
  return (
    <div className={props.className}>
      <label className="relative block mb-5 text-left">
        {
          props.label ?
          <span className="ml-3 text-sm text-gray-600">{ props.label }</span> : null
        }
        <input
          type="color"
          max-length="6"
          className="mt-1 placeholder:italic placeholder:text-slate-400 h-12 block bg-white w-full border border-slate-300 rounded-md p-3 shadow-sm text-gray-900 focus:outline-none focus:border-gray-500 focus:ring-gray-500 focus:ring-1 sm:text-sm"
          name="file"
          onChange={(change: any) => { props.onChangeText(change.target.value) }}
          value={props.value}
        />
        {
          props.note ?
          <span className="text-gray-400 text-xs">{ props.note }</span> : null
        }
      </label>
    </div>
  )
}