import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  ActivateButton,
  DefaultButton,
  OutlineButton,
  RadioButton,
  RedRemoveButton,
} from "../../../components/Buttons";
import { MainHeader } from "../../../components/Headers";
import {
  ColourInput,
  DateInput,
  DefaultInput,
  FileInput,
  MultilineInput,
} from "../../../components/Inputs";
import { DefaultSelect } from "../../../components/Selects";
import { getToken } from "../../../context/auth";
import { isDateExpired } from "../../../lib/isDateExpired";

interface PassTemplate {
  id: string;
  option: string;
}

function EditDynamicLinkPage() {

  const navigate = useNavigate();

  const params = useParams();

  const [linkId, setLinkId] = useState<string>(params.linkId as string)

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [savingChanges, setSavingChanges] = useState<boolean>(true);

  const [passTemplates, setPassTemplates] = useState<PassTemplate[]>([]);

  const [urlStringInput, setUrlStringInput] = useState<string>("");

  const [selectedPassTemplate, setSelectedPassTemplate] = useState<string>("");

  useEffect(() => {

    

    loadLink();
    loadPasses();
  }, [])
  

  // functions

  const loadLink = async() => {
    

    

    // axios post request
    await axios.post(process.env.REACT_APP_API_URL + 'dynamic-links/get-dynamic-link', {
      dynamicLinkId: linkId
    },{
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Authorization": await getToken() as string
      },
    }).then((response) => {

      

      const { urlString, passTemplate } = response.data.dynamicLink;

      setUrlStringInput(urlString);
      setSelectedPassTemplate(passTemplate.id);
      setIsLoading(false);

      return;

    }).catch(error => {
      
      try {
        alert(error.response.data.message)
      }
      catch {
        alert("Something went wrong")
      }
    })
  }

  const loadPasses = async() => {
    

    // axios post request
    await axios.get(process.env.REACT_APP_API_URL + 'pass-templates/get-templates-list', {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Authorization": await getToken() as string
      },
    }).then((response) => {

      

      const { templates } = response.data;

      setPassTemplates(templates.map((template: any) => {
        return {
          id: template.id,
          option: template.title
        }
      }));


    }).catch(error => {
      
      try {
        alert(error.response.data.message)
      }
      catch {
        alert("Something went wrong")
      }
    })
  }

  // TODO: Changing slide resets the value of the elements

  const saveChangesFunction = () => {

    
    if (!urlStringInput || !selectedPassTemplate || (passTemplates.findIndex(x => x.id === selectedPassTemplate) === -1)) {
      alert('Please fill out all required fields')
      return;
    }

    setSavingChanges(true);

    axios.post(process.env.REACT_APP_API_URL + 'dynamic-links/create-dynamic-link', {
      urlString: urlStringInput,
      templateId: selectedPassTemplate
    }, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Authorization": getToken() as string
      },
    }).then(async(response) => {
      
      setSavingChanges(false);

     
      navigate('/dynamic-links')


    }).catch((error) => {
      
      setSavingChanges(false);
      alert(error.response.data.message)
    })

  }

  // TODO: Expiry Date validation

  return (
    <>
      <div className="min-h-screen bg-gray-50">
        <MainHeader />
        <header className="bg-white shadow">
          <div className="flex flex-row justify-between items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl tracking-tight font-bold text-gray-900">
              Edit Dynamic Link
            </h1>
            <ActivateButton
              text={"Save Changes"}
              onPress={saveChangesFunction}
              disabled={(!urlStringInput || !selectedPassTemplate || (passTemplates.findIndex(x => x.id === selectedPassTemplate) === -1)) ? true : false}
            />
          </div>
        </header>
        <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <div className="card rounded border-1 drop-shadow bg-white">
              <div className="flex flex-col px-5 pt-3 md:flex-row h-full gap-10">
                <div className="w-screen md:w-3/6 h-full">
                  <h1 className="text-3xl font-medium text-gray-900 mb-5">
                  Edit Dynamic Link
                  </h1>
                  <p className="mb-5  text-gray-600">Here you can edit your account information. All passes that include the following details will be updated.</p>
                  <p className="mb-5  text-gray-600">Your Link will be: <p className="font-bold">https://issuing.walletpass.co.uk/passes/{urlStringInput}</p></p>
                  
                  <DefaultInput label="URL String (max length 30)" placeholder="example-url-string" onChangeText={(change: string) => setUrlStringInput(change)} value={urlStringInput} maxLength={30} />
                  <DefaultSelect placeholder={"Connected Pass Template"} options={passTemplates} onSelect={(change: string) => setSelectedPassTemplate(change)} value={selectedPassTemplate} />
                </div>
              </div>
            </div>
          </div>
        </div>
        </main>
      </div>
    </>
  );
}

export default EditDynamicLinkPage;
