export function passwordValidator(password: string): boolean {
  // does the password include more than 8 characters and at least one number and one uppercase letter and one lowercase letter and any character?

  // does the password contain less than 8 characters?
  if (password.length < 8) {
    return false;
  }

  // does the password contain at least one number?
  if (!/\d/.test(password)) {
    return false;
  }

  // does the password contain at least one uppercase letter?
  if (!/[A-Z]/.test(password)) {
    return false;
  }

  // does the password contain at least one lowercase letter?
  if (!/[a-z]/.test(password)) {
    return false;
  }

  return true;
}
