import React from "react";
import { Outlet } from "react-router";
import { Navigate } from "react-router-dom";

interface ProtectedRoutesAttributes {
  currentUser: any
}

const NotLoggedInRoutes = (props: ProtectedRoutesAttributes) => {

  return props.currentUser ? (props.currentUser.accountType === 'admin' ? <Navigate to="/users" /> : <Navigate to="/passes" />) : <Outlet /> ;

  // return (
  //   <Switch>
  //     <Route path="/dashboard" component={Dashboard} />
  //     <Route path="/profile" component={Profile} />
  //   </Switch>
  // );
}

export default NotLoggedInRoutes;