export function convertDateToString(date: Date): string {

  

  // return date dd-mm-yyyy
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear() < 10 ? `0${date.getFullYear()}` : date.getFullYear();
  return `${day}-${month}-${year}`;
}

export function convertDateToStringYYYYMMDD(date: Date): string {

  

  // return date dd-mm-yyyy
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear() < 10 ? `0${date.getFullYear()}` : date.getFullYear();
  return `${year}-${month}-${day}`;
}