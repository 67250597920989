import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { DefaultButton, RedRemoveButton, TextButton } from '../../../components/Buttons';
import { MainHeader } from '../../../components/Headers';
import { DateInput, DefaultInput, EmailInput, PasswordInput, TelephoneInput } from '../../../components/Inputs';
import { DefaultSelect } from '../../../components/Selects';
import { resetUserSession, getUser, getToken, setUserSession } from '../../../context/auth';

export default function EditAccountPage() {

  const navigate = useNavigate();

  const [firstNameInput, setFirstNameInput] = useState<string>("");
  const [lastNameInput, setLastNameInput] = useState<string>("");
  const [companyNameInput, setCompanyNameInput] = useState<string>("");
  const [websiteUrlInput, setWebsiteUrlInput] = useState<string>("");
  const [contactEmailInput, setContactEmailInput] = useState<string>("");
  const [contactPhoneInput, setContactPhoneInput] = useState<string>("");

  const [savingChanges, setSavingChanges] = useState<boolean>(false);

  const [showSignOutModal, setshowSignOutModal] = useState<boolean>(false);

  useEffect(() => {
    getAccountFunction()
  
  }, [])
  

  const getAccountFunction = () => {
    const user = getUser()

    

    setFirstNameInput(user.accountInfo.firstName || "")
    setLastNameInput(user.accountInfo.lastName || "")
    setCompanyNameInput(user.accountInfo.companyName || "")
    setWebsiteUrlInput(user.accountInfo.websiteUrl || "")
    setContactEmailInput(user.contactInfo.email || "")
    setContactPhoneInput(user.contactInfo.phone || "")

  }

  const inputsValid = () => {
    if (!firstNameInput || !lastNameInput || !companyNameInput || !websiteUrlInput || !contactEmailInput || !contactPhoneInput) {
      return false;
    }
    return true;
  }

  const saveChangesFunction = () => {

    // are all input valid?
    
    if (!inputsValid) {
      alert("Please fill in all fields")
      return;
    }

    setSavingChanges(true);

    axios.post(process.env.REACT_APP_API_URL + 'account/edit-account', {
      firstName: firstNameInput,
      lastName: lastNameInput,
      companyName: companyNameInput,
      websiteUrl: websiteUrlInput,
      contactEmail: contactEmailInput,
      contactPhone: contactPhoneInput
    }, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        'Authorization': getToken() as string
      }
    }).then(response => {

      setSavingChanges(false);

      setUserSession(response.data.user, getToken() as string)


      navigate('/passes')

    }).catch(error => {

      setSavingChanges(false);
      try {
        alert(error.response.data.message)
      }
      catch {
        alert("Something went wrong")
      }
    })

  }

  const signOutFunction = () => {

    resetUserSession();
    localStorage.removeItem('refreshToken');
    
    window.location.reload();

  }

  return (
    <>
      <div className="min-h-screen bg-gray-50">
        <MainHeader />
        <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto flex flex-row justify-between items-center py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl tracking-tight font-bold text-gray-900">My Account</h1>
          <TextButton text="Sign Out" onPress={() => { setshowSignOutModal(true) }} />
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <div className="card rounded border-1 drop-shadow bg-white">
              <div className="flex flex-col px-5 pt-3 md:flex-row h-full gap-10">
                <div className="w-screen md:w-3/6 h-full">
                  <h1 className="text-3xl font-medium text-gray-900 mb-5">
                    Account Information.
                  </h1>
                  <p className="mb-5  text-gray-600">Here you can edit your account information. All passes that include the following details will be updated.</p>
                  <DefaultInput label="First Name" placeholder="Enter your first name" onChangeText={(change: string) => setFirstNameInput(change)} value={firstNameInput} />
                  <DefaultInput label="Last Name" placeholder="Enter your last name" onChangeText={(change: string) => setLastNameInput(change)} value={lastNameInput} />
                  <DefaultInput label="Company Name" placeholder="Enter your company name" onChangeText={(change: string) => setCompanyNameInput(change)} value={companyNameInput} />
                  <DefaultInput label="Website URL" placeholder="Enter your website url" onChangeText={(change: string) => setWebsiteUrlInput(change)} value={websiteUrlInput} />
                </div>
                <div className="w-screen md:w-3/6 h-full">
                  <h2 className="text-3xl font-medium text-gray-900 mb-5">Contact Information</h2>
                  <p className="mb-5 text-gray-600">This information will appear on the back of your wallet pass. Please ensure it is correct.</p>
                  <EmailInput label="Email Address" placeholder="Enter your contact email" onChangeText={(change: string) => setContactEmailInput(change)} value={contactEmailInput} />
                  <TelephoneInput label="Telephone Number" placeholder="Enter your contact number" onChangeText={(change: string) => setContactPhoneInput(change)} value={contactPhoneInput} />
                </div>
              </div>
              <div className="flex flex-col px-5 pb-3 justify-end md:flex-row h-full" >
                <DefaultButton text={savingChanges ? 'Saving Changes...' : 'Save Changes'} disabled={!inputsValid || savingChanges ? true : false} onPress={saveChangesFunction} />
              </div>
            </div>
          </div>
        </div>
      </main>
      {
      showSignOutModal ? (
        <div className="fixed inset-0 z-40 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25">
            <div className="absolute inset-0 bg-gray-500 bg-opacity-25">
              {/* Create a modal element */}

              <div className="absolute inset-0 flex items-center justify-center">
                <div className="max-w-lg w-full px-4 py-3">
                  <div className="flex flex-col justify-between items-center bg-white rounded w-full p-5 drop-shadow">
                    <div className="flex flex-col">
                      <h2 className="text-xl font-semibold mb-3">Sign Out?</h2>
                      <p className="text-gray-600 mb-5">Are you sure you would like to sign out of your account?</p>
                      <div className="flex flex-row justify-end">
                        <TextButton text="Cancel" onPress={() => { setshowSignOutModal(false) }} />
                        <DefaultButton text="Sign Out" onPress={signOutFunction} className="self-end" />
                      </div>
                      </div>
                    </div>
                  </div>
                </div> 

            </div>
          </div>
        </div>
      ) : null
    }
      </div>
    </>
  );
}
